// img and icons for home page
import app1 from "../assets/appIcon_Banners/taskWask.webp";
import app2 from "../assets/appIcon_Banners/coinDay.webp";
import app3 from "../assets/appIcon_Banners/rewardX.webp";
import app4 from "../assets/appIcon_Banners/taskBud.webp";
import app5 from "../assets/appIcon_Banners/cashHunt.webp";
import app6 from "../assets/appIcon_Banners/moneyX.webp";
import app7 from "../assets/appIcon_Banners/taskPlus.webp";
import app8 from "../assets/appIcon_Banners/zookApp.webp";
import taskWaskLogo from "../assets/appIcon_Banners/taskwaskLogo.webp";
import coindaylogo from "../assets/appIcon_Banners/coindaylogo.webp";
import rewardxlogo from "../assets/appIcon_Banners/rewardxlogo.webp";
import taskbudlogo from "../assets/appIcon_Banners/taskbudlogo.webp";
// import rewardleLogo from "../assets/appIcon_Banners/Rewardlelogo.webp";
import cashHuntLogo from "../assets/appIcon_Banners/cashhuntlogo.webp";
import moneyXlogo from "../assets/appIcon_Banners/moneyXlogo.svg";
import taskPluslogo from "../assets/appIcon_Banners/taskPluslogo.webp";

// img and icons for produt page
import BigBanner1 from "../assets/appIcon_Banners/taskWaskBanner.webp";
import BigBanner2 from "../assets/appIcon_Banners/rewardxBanner.webp";
import BigBanner3 from "../assets/appIcon_Banners/coinDayBanner.webp";
import BigBanner4 from "../assets/appIcon_Banners/taskBudBanner.webp";
import BigBanner5 from "../assets/appIcon_Banners/taskplusBanner.webp";
import BigBanner6 from "../assets/appIcon_Banners/zookBigBanner.webp";

import appBanner1 from "../assets/appIcon_Banners/cashHunt.webp";
import appBanner2 from "../assets/appIcon_Banners/zookApp.webp";
import appBanner3 from "../assets/appIcon_Banners/moneyX.webp";
import appBanner4 from "../assets/appIcon_Banners/taskPlus.webp";
import taskPlusIcon from "../assets/appIcon_Banners/taskPluslogo.webp";
import rewardleIcon from "../assets/appIcon_Banners/Rewardlelogo.webp";
import cashHuntIcon from "../assets/appIcon_Banners/cashHuntIcon.webp";
import zookIcon from "../assets/appIcon_Banners/zookIcon.webp"
import moneyXIcon from "../assets/appIcon_Banners/moneyXlogo.svg";
import appIcon1 from "../assets/appIcon_Banners/taskwaskLogo.webp";
import appIcon2 from "../assets/appIcon_Banners/rewardxlogo.webp";
import appIcon3 from "../assets/appIcon_Banners/coindaylogo.webp";
import appIcon4 from "../assets/appIcon_Banners/taskbudlogo.webp";
import appIcon5 from "../assets/appIcon_Banners/taskPluslogo.webp";


// img and icons for reward page
import step1 from "../assets/rewardpage/rewardStep1.webp";
import step2 from "../assets/rewardpage/rewardStep2.webp";
import step3 from "../assets/rewardpage/rewardStep3.webp";
import bank from "../assets/rewardpage/bank.webp";
import card from "../assets/rewardpage/card.webp";
import dollarBag from "../assets/rewardpage/dollar-bag.webp";
// import wallet from "../assets/wallet.webp";
import ravi from "../assets/rewardpage/raviImg.webp";
import priya from "../assets/rewardpage/priyaImg.webp";
import rohan from "../assets/rewardpage/rohanImg.webp";


// img and icons for about page
import trophyCupIcon from "../assets/productspage/icons/trophyCupIcon.webp"
import giftCardIcon from "../assets/productspage/icons/giftCardIcon.webp"
import piggyBankIcon from "../assets/productspage/icons/piggyBankIcon.webp"
import console2Icon from "../assets/productspage/icons/console2Icon.webp"
import mysterBoxIcom from "../assets/productspage/icons/mysteryBoxIcon.webp"
import bitLabIcon from "../assets/productspage/icons/bitLabsIcon.webp"
import XOIcon from "../assets/productspage/icons/XOicon.webp"
import spinWheelIcon from "../assets/productspage/icons/spinWheelIcon.webp"
import shakeMobileIcon from "../assets/productspage/icons/shakeMobileIcon.webp"
import calIcon from "../assets/productspage/icons/calIcon.webp"
import consoleIcon from "../assets/productspage/icons/consoleIcon.webp"
import chestIcon from "../assets/productspage/icons/chestIcon.webp"
import todoIcon from "../assets/productspage/icons/todoIcon.webp"
import fireIcon from "../assets/productspage/icons/fireIcon.webp"
import handPhoneIcon from '../assets/hand-phone.webp'
import rocket from "../assets/aboutpage/aboutRocket.webp";
import lightning from "../assets/aboutpage/aboutThunder.webp";
import lens from "../assets/aboutpage/aboutMagnifier.webp";
import Abhinav from "../assets/aboutpage/Abhinav.webp";
import Rishav from "../assets/aboutpage/Rishav.webp";
import Khushaali from "../assets/aboutpage/Khushaali.webp";
import Namrata from "../assets/aboutpage/Namrata.webp";
import Anushka from "../assets/aboutpage/Anushka.webp";
import Jewel from "../assets/aboutpage/Jewel.webp";
import Abhishek from "../assets/aboutpage/Abhishek.webp";
import Shabaj from "../assets/aboutpage/Shabaj.webp";
import Devansh from "../assets/aboutpage/Devansh.webp";
import Shweta from "../assets/aboutpage/shweta.webp";
// import Newbee from "../assets/aboutpage/newbee.webp";


// *********  Home Page *********
export const homePageAppList = [
  {
    appName: "TaskWask",
    id:'taskwask',
    appDesc: "100K+ Downloads",
    btn:false,
    appImg: app1,
    iconImg: taskWaskLogo,
    bgColor: "#d06edc",
    link: "https://play.google.com/store/apps/details?id=com.coinday.app&hl=en_US",
  },
  {
    appName: "CoinDay",
    id:'coinday',
    appDesc: "1MN+ Downloads",
    appImg: app2,
    iconImg: coindaylogo,
    btn: false,
    bgColor: "#d06edc",
    link: "https://play.google.com/store/apps/details?id=com.coinday.app&hl=en_US",
  },
  {
    appName: "RewardX",
    id:'rewardx',
    appDesc: "1MN+ Downloads",
    appImg: app3,
    bgColor: "#c67658",
    btn: false,
    iconImg: rewardxlogo,
    link: "https://play.google.com/store/apps/details?id=com.taskbud.app&hl=en_US",
  },
  {
    appName: "TaskBud",
    id:'taskbud',
    appDesc: "1MN+ Downloads",
    appImg: app4,
    btn: false,
    bgColor: "#74caee",
    iconImg: taskbudlogo,
    link: "https://play.google.com/store/apps/details?id=com.cashcoin.app&hl=en_US",
  },
  {
    appName: "TaskPlus",
    id:'taskplus',
    appDesc: "100k+ Downloads",
    appImg: app7,
    btn:false,
    bgColor: "#53c090",
    iconImg: taskPluslogo,
    link: "https://play.google.com/store/apps/details?id=com.loftlime.rewardx&hl=en_US",
  },
  {
    appName: "Zook",
    id:'zook',
    appDesc: "100k+ Downloads",
    btn:false,
    appImg: app8,
    bgColor: "#53c090",
    iconImg: zookIcon,
    link: "https://play.google.com/store/apps/details?id=com.loftlime.rewardx&hl=en_US",
  },
  {
    appName: "CashHunt",
    id:'commingsoon',
    appDesc: "Coming Soon",
    appImg: app5,
    bgColor: "#53c090",
    iconImg: cashHuntIcon,
    link: "https://play.google.com/store/apps/details?id=com.loftlime.rewardx&hl=en_US",
  },
  {
    appName: "MoneyX",
    id:'commingsoon',
    appDesc: "Coming Soon",
    appImg: app6,
    bgColor: "#53c090",
    iconImg: moneyXlogo,
    link: "https://play.google.com/store/apps/details?id=com.loftlime.rewardx&hl=en_US",
  },
  
];
export  const communityDataHomePage = [
	{
		title:"2.5MN+",
		description:"Monthly Active Users",
	},
	{
	 title:"300+",
	 description:"Brands & Campaigns",
	},
	{
		title:"10CR+",
		description:"Payout to users"
	},
	{
		title:"9CR+",
		description:"Revenue Generated"
	}
];

  
// *********  Product Page *********
export const productList = [
    {
      img: BigBanner1,
      id:'taskwask',
      appName: "TaskWask",
      appIcon: appIcon1,
      // appColor: colors.green,
      link:"https://play.google.com/store/apps/details?id=com.loftlime.taskwask&hl=en_IN&gl=US",
      stars: 4.2,
      downloads: "100k+",
      description:"Earn real rewards for completing quick & easy tasks!  With TaskWask, you can turn your free time into cash or gift cards. ",     
       offerings: [
        {
          icon:trophyCupIcon,
          title: "Leaderboard",
          description: "Climb to the top and add a fun twist to earning.",
        },
        {
          icon:giftCardIcon,
          title: "Earn cash & gift cards",
          description: "Redeem your cash & gift cards immediately, no waiting!",
        },
        {
          icon:handPhoneIcon,
          title: "Quick & easy tasks",
          description: "No sweat, just small tasks you can do anywhere, anytime.",
        },
        {
          icon:piggyBankIcon,
          title: "MoneyPool",
          description: "Join the Moneypool queue and win ₹50 assured cash reward.",
        },
      ],
    },
    {
      img: BigBanner2,
      id:'rewardx',
      appName: "RewardX",
      appIcon: appIcon2,
      // appColor: colors.yellow,
      link:'https://play.google.com/store/search?q=rewardx&c=apps&hl=en_IN&gl=US',
      stars: 4.3,
      downloads: "1MN+",
      description:
        "Earn rewards with fun and easy tasks on RewardX! Invite friends and boost your earnings together! Download RewardX and start rewarding.",
      offerings: [
        {
          icon:console2Icon,
          title: "Playtime",
          description: "Fun & rewarding! Get paid to play games you love.",
        },
        {
          icon:spinWheelIcon,
          title: "Spin & Win",
          description: "Try your luck and win exciting prizes with Spin & Win!",
        },
        {
          icon:trophyCupIcon,
          title: "Leaderboard",
          description: "Top the charts & claim bonus rewards in RewardX!",
        },
        {
          icon:shakeMobileIcon,
          title: "RewardX shake",
          description: "Task done? Shake for a surprise reward in RewardX! ",
        },
      ],
    },
    {
      img: BigBanner3,
      id:'coinday',
      appName: "CoinDay",
      appIcon: appIcon3,
      // appColor: colors.yellow,
      link:'https://play.google.com/store/search?q=coinday&c=apps&hl=en_IN&gl=US',
      stars: 4.4,
      downloads: "1MN+",
      description:
        "Earn real cash and gift cards for doing fun stuff!  Play games, complete tasks, and invite friends with CoinDay.",
      offerings: [
        {
          icon:handPhoneIcon,
          title: "Taskoffer",
          description: "Complete easy tasks and get paid - it's that simple!",
        },
        {
          icon:calIcon,
          title: "Daily Bonus",
          description: "Just open the app and get Daily Bonus rewards every day!",
        },
        {
          icon:consoleIcon,
          title: "Mini-games",
          description: "Fun & rewarding! Play mini-games and rack up coins while you have a blast.",
        },
        {
          icon:chestIcon,
          title: "Chest",
          description: "Open surprise chests for exciting rewards and a chance to win big!",
        },
      ],
    },
    {
      img: BigBanner4,
      id:'taskbud',
      appName: "TaskBud",
      appIcon: appIcon4,
      // appColor: colors.yellow,
      link:"https://play.google.com/store/search?q=taskbud&c=apps&hl=en_IN&gl=US",
      stars: 4.5,
      downloads: "1MN+",
      description:
        "You can make money while performing easy-to-do tasks and have fun at the same time with TaskBud.",
      offerings: [
        {
          icon:console2Icon,
          title: "Playtime",
          description: "Earn rewards and have fun with engaging mini-games!",
        },
        {
          icon:todoIcon,
          title: "Daily task",
          description: "Little victories every day lead to major accomplishments!",
        },
        {
          icon:fireIcon,
          title: "Daily Streaks",
          description: "Build a habit and watch your rewards grow!",
        },
        {
          icon:trophyCupIcon,
          title: "leaderboard",
          description: "Climb the ranks and compete with friends for top rewards!",
        },
      ],
    },
    {
      img: BigBanner5,
      id:'taskplus',
      appName: "TaskPlus",
      appIcon: appIcon5,
      // appColor: colors.yellow,
      link:"https://play.google.com/store/apps/details?id=com.loftlime.taskplus&hl=en_IN&gl=US",
      stars: 4.5,
      downloads: "58K+",
      description:
        "Task+ redefines the way you earn rewards, making it both effortless and  enjoyable! You can open the app anytime, anywhere to begin earning real  money.",
      offerings: [
        {
          icon:todoIcon,
          title: "Task Diary",
          description: "Keep track of your daily tasks with Taskplus!",
        },
        {
          icon:fireIcon,
          title: "Popular Tasks",
          description: "Maximize your earnings by completing trending tasks.",
        },
        {
          icon:handPhoneIcon,
          title: "Refer and Earn Together!",
          description: "Invite friends & earn rewards together with Taskplus!",
        },
        {
          icon:giftCardIcon,
          title: "Instant Cash Withdrawals",
          description: "Convert your coins into real cash instantly without delays!",
        },
      ],
    },
    {
      img: BigBanner6,
      id:'zook',
      appName: "Zook",
      appIcon: zookIcon,
      // appColor: colors.yellow,
      link:"https://play.google.com/store/search?q=zook&c=apps&hl=en_IN",
      stars: 4.5,
      downloads: "100K+",
      description:
        "Step into a vibrant world where every tap leads to excitement and every swipe brings you closer to earning rewards! Zook is your reward-earning app, offering instant coins, and endless rewards.",
      offerings: [
        {
          icon:mysterBoxIcom,
          title: "Mystery Box",
          description: "Open mystery boxes and earn coins. ",
        },
        {
          icon:bitLabIcon,
          title: "Survey Walls",
          description: "Maximize your earnings by taking surveys",
        },
        {
          icon:XOIcon,
          title: "Play Minigames & Earn",
          description: "Play minigames and earn rewards while having fun.",
        },
        {
          icon:giftCardIcon,
          title: "Instant Cash Withdrawals",
          description: "Convert your coins into real cash instantly without delays!",
        },
      ],
    },
  ];
export   const soonProductList = [
    {
      img: appBanner1,
      id:'commingsoon',
      appIcon: cashHuntIcon,
      appName: "CashHunt",
    },
    {
      img: appBanner3,
      appIcon: moneyXIcon,
      appName: "MoneyX",
    },
    // {
    //   img: appBanner4,
    //   appIcon: taskPlusIcon,
    //   appName: "TaskPlus",
    // },
  ];


// *********  Reward Page *********

export const RewardSteps = [
  {
    info: "Choose from an array of apps",
    img: step1,
  },
  {
    info: "Play, Engage and Conquer Tasks!",
    img: step2,
  },
  {
    info: "Unlock a World of Rich Rewards!",
    img: step3,
  },
];
export const rewardOptionList = [
  {
    img: bank,
    title: "Bank Transfer",
    subtitle:
      "Instantly withdraw your money directly into your bank account via UPI option from in-app.",
  },
  {
    img: card,
    title: "Gift Card",
    subtitle:
      "From Amazon and Flipkart to Myntra and Google Play, we've got gift card vouchers for al.",
  },
  {
    img: dollarBag,
    title: "In-game Credits",
    subtitle:
      "Elevate your gaming experience with our in-game credits. Perfect for the gamers in our community.",
  },
  // {
  //   img: wallet,
  //   title: "Paytm Wallet",
  //   subtitle:
  // 	"Turn your earnings into real money and transfer it directly to your Paytm Wallet.",
  // },
];
export const successStoriesData = [
  {
    img: ravi,
    name: "Meet Ravi",
    story:
      "Our CoinDay user, used his earnings to buy study materials on Amazon. With LoftLime, he turns his spare time into real rewards.",
  },
  {
    img: priya,
    name: "Meet Priya",
    story:
      "Our RewardX user from Bangalore who regularly redeems her rewards for Myntra gift cards. She's turned her love for mobile games into a way to revamp her wardrobe.",
  },
  {
    img: rohan,
    name: "Meet Rohan",
    story:
      "Tech enthusiast from Mumbai. Thanks to LoftLime, he regularly tops up his Google Play account with our gift cards, keeping his app collection up-to-date.",
  },
];

// *********  About  *********
export const aboutRewardingList = [
  {
    img: rocket,
    title: "The Genesis",
    subtitle:
      "Our journey began as we witnessed app users investing valuable time into various applications, only to be left without meaningful rewards. Existing promises often fell short, leaving a limited age group disappointed.",
  },
  {
    img: lightning,
    title: "The Spark",
    subtitle:
      "An illuminating thought ignited within us: What if we could create a platform that not only offered genuine rewards but also catered to a diverse audience? What if we could transform each user's experience into something uniquely rewarding?",
  },
  {
    img: lens,
    title: "The Essense",
    subtitle:
      "Introducing LoftLime, where we revolutionise app experience by infusing it with irresistible rewards. Whether you desire Real Cash, Gift Card Vouchers, or in-game credits, LoftLime is your all-in-one solution for a tailored and rewarding app journey, accessible to all.",
  },
]
export const missionDataAbout = [
  {
    title: "10MN+",
    description: "Total Userbase",
  },
  {
    title: "2.5MN+",
    description: "Monthly Active users",
  },
  {
    title: "10CR+",
    description: "Payout to users",
  },
  {
    title: "300+",
    description: "Brands & Campaigns",
  },
];
export const teamDataAbout = [
  {
    img: Abhinav,
    name: "Abhinav Maurya",
    level: "Founder, CEO",
    linkedin: "https://www.linkedin.com/in/iabhiman/",
  },
  {
    img: Rishav,
    name: "Rishav Raina",
    level: "Founder, CTO",
    linkedin:"https://www.linkedin.com/in/rishav-raina/"
  },
  {
    img: Khushaali,
    name: "Khushaali Srivastava",
    level: "HR Manager",
    linkedin:"https://www.linkedin.com/in/khushaali-srivastava-38884a192/"
  },
  {
    img: Anushka,
    name: "Anushka Tyagi",
    level: "Product Manager",
    linkedin:"https://www.linkedin.com/in/anushkatyagiii/"
  },
  {
    img: Abhishek,
    name: "Abhishek Vishwakarma",
    level: "Project Manager",
    linkedin:"https://www.linkedin.com/in/abhishek-vishwakarma-22913b174/"
  },
  {
    img: Jewel,
    name: "Jewel Thomas",
    level: "SDE I (Web Developer)",
    linkedin:"https://www.linkedin.com/in/jewel-thomas-707432197/"
  },
  {
    img: Shabaj,
    name: "Shabaj Ansari",
    level: "SDE I (Android Developer)",
    linkedin:"https://www.linkedin.com/in/shabaj-ansari-696426202/"
  },
  {
    img: Devansh,
    name: "Devansh Kumar",
    level: "UI/UX Designer",
    linkedin:"https://www.linkedin.com/in/abcdevansh/"
  },
  {
    img: Shweta,
    name: "Shweta Sharma",
    level: "Mobile Delivery Executive",
    linkedin:"https://www.linkedin.com/in/shweta-sharma-62a0a6307/"
  },
  // {
  //   img: Newbee,
  //   name: "Join Our Team!",
  //   level: "See Open Positions",
  //   linkedin:"https://www.linkedin.com/company/leadminthq/jobs/"
  // },
];



export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
