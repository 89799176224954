import React from 'react';
import { styled } from 'styled-components';

const UserCard = ({ className, isActive, name, tag, img, onClick }) => {
	return (
		<Container className={className} isActive={isActive} onClick={onClick}>
			<div className="image">
				<img src={img} alt="user" />
			</div>

			<div className="name-tag">
				<span className="name">{name}</span>
				<span className="tag">{tag}</span>
			</div>
		</Container>
	);
};

const Container = styled.div`
	.image {
		border: 2px solid ${({ isActive }) => (isActive ? ' lime' : 'transparent')};
		width: 60px;
		height: 60px;
		border-radius: 50px;
		transition: all 0.2s linear;
		cursor: pointer;

		&:hover {
			border: 2px solid #25702487;
		}
		img {
			height: 100%;
			width: 100%;
			border-radius: 50px;
			opacity:${({ isActive }) => (isActive ? 1 : 0.6)};

		}
	}
	.name-tag {
		display: flex;
		flex-direction: column;
		gap: 3px;
		font-size: 14px;
		letter-spacing: 0.2px;
		opacity: 0.8;
		margin-top: 5px;
		.tag {
			font-weight: 100;
			display:none;
		}
	}
	@media screen and (max-width: 600px) {
		img {
			height: 100%;
			width: 100%;
			border-radius: 50px;
			opacity:${({ isActive }) => (isActive ? 1 : 0.6)};

		}
		.name-tag {
			font-size: 13px;
			.tag{
				display:block;
			}
		}
	}
`;

export default UserCard;
