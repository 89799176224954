import React from 'react';
import { styled } from 'styled-components';

const RadialBg = ({ style, className, size = 100 }) => {
	return <Container size={size} className={className} style={{ ...style }} />;
};

const Container = styled.div`
	background: radial-gradient(
		50% 50% at 50% 50%,
		rgba(42, 161, 76, 0.3) 16.77%,
		#f7cd6200 100%
	);
	width: ${({ size }) => `${size}px`};
	height: ${({ size }) => `${size}px`};
`;

export default RadialBg;
