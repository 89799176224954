import React, { useEffect } from "react";
import { styled } from "styled-components";
import { ImArrowRight2 } from "react-icons/im";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MessageSection from '../sections/MessageSection';
import colors from "../config/colors";
import SectionTitle from "../components/SectionTitle";
import loftlime from "../assets/icons/loftime.webp";
import CardBgColor from "../components/CardBgColor";
import TeamMember from "../components/TeamMember";

import AppBtn from "../components/AppBtn";
import CommunityTrusteby from "../components/CommunityTrusteby";
import moon from "../assets/commonImg/bigBlackBall.webp";
import gridImg from "../assets/commonImg/hero-grid.webp"
import greenGradBg from '../assets/commonImg/greenGradientBG.webp'
import ribbion from "../assets/commonImg/ribbonLeft.webp"

import { aboutRewardingList, missionDataAbout, teamDataAbout } from "../utils/constants";


const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <NavBar />
      <div className="topWrapper">
      <img src={greenGradBg} alt="greenGradBg" style={{position: 'absolute', top: 0, right: "16%", width: 'auto', height: '30vw', zIndex: 0}} />
      <img src={ribbion} alt="ribbion" style={{position: 'absolute', top: "20%", height: '10vw', left:0, zIndex: 0}} />
      <img src={moon} width={60} alt="moon" style={{position: 'absolute', top: "10%", right:'5%', zIndex: 0}}  />
      <div className="top-section">
        <SectionTitle center className="title">
          <img src={loftlime} alt="loftlime" />
          <p> Rewarding Your App Experiences</p>
        </SectionTitle>
      </div>

      <div className="journey-section app-padding">
        {aboutRewardingList.map((j, i) => (
          <CardBgColor className="journey-card" key={j.title} >
            <img src={j.img} alt="tag" />
            <p className="j-title">{j.title}</p>
            <p className="j-subtitle">{j.subtitle}</p>
          </CardBgColor>
        ))}
      </div>
      </div>


      <div className="mission-section app-padding">
        <SectionTitle isSabo center className="title">
          OUR <span>MISSION</span>
        </SectionTitle>
        <div className="mission">
          At LoftLime, we aim to create a rewarding ecosystem that empowers app
          users in India and beyond. We are dedicated to transforming the way
          people interact with apps - making it a rewarding experience for all.
        </div>

        <CommunityTrusteby data={missionDataAbout} />

        {/* <div className="empty"></div> */}
      </div>

      <div className="team-section app-padding">
        <SectionTitle isSabo center>
          MEET THE TEAM BEHIND
          <img src={loftlime} alt="loftlime" style={{ marginLeft: "1rem" }} />
        </SectionTitle>

        <div className="team">
          {teamDataAbout.map((member, i) => (
            <TeamMember
              img={member.img}
              name={member.name}
              level={member.level}
              description={member.description}
              key={i}
              linkedin={member.linkedin}
            />
          ))}
        </div>
      </div>

      <div className="join-team-section app-padding">
        <CardBgColor className="join-card">
          <img src={moon} alt="moon" className="bigPlanet" />
          <img src={moon} alt="moon" className="smallPlanet" />
          <div className="left">
            <p className="jt-title">Become a Part of Our Journey!</p>
            <p className="jt-subtitle">
              We're building a world-class team to help us bring our vision to
              life. Join us in making app usage a rewarding experience for all.
            </p>
          </div>
          <AppBtn onClick={()=>window.open('https://www.linkedin.com/company/leadminthq/mycompany/','_blank')}>
            Join our Team <ImArrowRight2 />
          </AppBtn>
        </CardBgColor>
      </div>

      <MessageSection />

      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background: ${colors.black};
  min-height: 100vh;
  color: ${colors.white};

  .topWrapper{
    background: url(${gridImg});
    background-size: 200px;
    background-repeat: repeat;
    position: relative;
  }
  .top-section {
    min-height: 350px;
    height: fit-content;
    text-align: center;
    padding-top: 154px;
   
    .title {
      color: rgba(255, 255, 255, 0.9);
      text-align: center;
      font-family: Lexend;
      font-size: 55px;
      font-style: normal;
      font-weight: 300;
      line-height: 76px;
      img {
        margin-bottom: 10px;
      }
    }
  }
  .journey-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px;
    padding-top: 80px;
    padding-bottom: 154px;
    .journey-card {
      position: relative;
      padding: 100px 35px 35px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      img {
        position: absolute;
        top: 0;
        right: 20px;
        height: 100px;
        width: auto;
        opacity: 0.1;
      }
      .j-title {
        font-size: 18px;
        opacity: 0.8;
      }
      .j-subtitle {
        opacity: 0.7;
        font-weight: 200;
        line-height: 25px;
        letter-spacing: 0.4px;
      }
    }
  }

  .mission-section {
    .mission {
      opacity: 0.7;
      font-weight: 200;
      line-height: 25px;
      letter-spacing: 0.4px;
      text-align: center;
      width: 70%;
      margin: 30px auto 10px;
    }
    .empty {
      background: #fff;
      width: 50%;
      margin: 40px auto;
      height: 300px;
      border-radius: 20px;
    }
  }
  .team-section {
    padding-bottom: 80px;
    .team {
      padding-top: 80px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      justify-content: center;
      grid-gap: 80px 35px;
      & > div {
        margin: auto;
      }
    }
  }
  .join-team-section {
    padding-bottom: 154px;
    position: relative;
    .join-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 40px 50px;
      overflow: hidden;
      .bigPlanet {
        position: absolute;
        top: -28%;
        right: 23%;
        width: 105.974px;
        height: 112.324px;
      }
      .smallPlanet {
        position: absolute;
        right: 22%;
        top: 22%;
        width: 24.439px;
        height: 25.904px;
      }
      .left {
        flex: 1;
      }

      .jt-title {
        font-size: 18px;
      }
      .jt-subtitle {
        opacity: 0.7;
        font-weight: 200;
        line-height: 25px;
        letter-spacing: 0.4px;
        margin-top: 10px;
      }

      button {
        height: fit-content;
        width: fit-content;
        z-index: 1;
        cursor: pointer;
        /* display: flex;
				align-items: flex-end;
				width: fit-content; */
      }
    }
  }
  @media screen and (max-width: 550px) {
    .join-team-section {
      .join-card {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .top-section {
      .title{
        p{
          font-size: 35px;
          line-height:40px;
        }
      }
    }
  }
`;

export default AboutPage;
