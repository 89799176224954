import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState, useRef, useEffect } from "react";
import { IoStar } from "react-icons/io5";
import { styled } from "styled-components";
import StarImg from "../assets/commonImg/starImgBlank.webp";
import SectionTitle from "../components/SectionTitle";
import RadialBg from "../components/RadialBg";
import UserCard from "../components/UserCard";
import user1 from "../assets/homepage/user1.webp";
import user2 from "../assets/homepage/user2.webp";
import user3 from "../assets/homepage/user3.webp";
import user4 from "../assets/homepage/user4.webp";
import user5 from "../assets/homepage/user5.webp";
import user6 from "../assets/homepage/user6.webp";
import Slider from "react-slick";

const testimonials = [
  {
    id:'ishant-dahiya',
    name: "Ishant Dahiya",
    tag: "RewardX User",
    img: user1,
    stars: 5,
    description:
      "It's very perfect application platform for earnings marketing very instant payout money and easy to earn that's the clear it's very wonderful app thanks for Reward X Thank you so much I am really very happy...☺️",
  },
  {
    id:'priyanka-sahni',
    name: "Priyanka Sahni",
    tag: "TaskBud User",
    img: user2,
    stars: 5,
    description:
      "I recently downloaded Taskbud, the data exchange and earn money app, and I have to say, I'm thoroughly impressed! This app provides a unique platform that allows me to earn money simply by sharing my anonymous data with companies that are looking for market research. The sign-up process was quick and easy, and the app is extremely user-friendly.",
  },
  {
    id:'kabir-dhawan',
    name: "Kabir Dhawan",
    tag: "CoinDay User",
    img: user3,
    stars: 5,
    description:
      "This is very useful app i love this app we can redeem any time and it is simple to get coins thank you very very much coinday💰",
  },
  {
    id:'vikram-singh',
    name: "Vikram Singh",
    tag: "CashCoin User",
    img: user4,
    stars: 5,
    description:
      "Guys I request you to use this app best app reddem instant and easy service I had task problem an I told them I instantly go my problem fixed and withdraw instant I love this app wish I could give 1million rating stars I love this app I am sharing to my friends also thanks cash app for reddem of Paytm cash",
  },
  {
    id:'aditi',
    name: "Aditi ",
    tag: "RewardX User",
    img: user5,
    stars: 5,
    description:
      "Very good app for earning money. I have earned 1000rs in 2 days. I have redeemed 1000rs in my paytm account. I am very happy. I have earned 1000rs in 2 days. I have redeemed 1000rs in my paytm account. I am very happy.",
  },
  {
    id:'anusha-sharma',
    name: "Anusha sharma",
    tag: "TaskWask User",
    img: user6,
    stars: 5,
    description:
      "I have earned 1000rs in 2 days. I have redeemed 1000rs in my paytm account. I am very happy. I have earned 1000rs in 2 days. I have redeemed 1000rs in my paytm account. I am very happy.",
  },
];


const TestimonialSection = () => {
  const [selectedT, setSelectedT] = useState('ishant-dahiya');

  const component = useRef();
  const pinSlider = useRef();

  const clickTestimonial = (index,id) => {
    setSelectedT(index);
    const testimonial = document.getElementById(id);
    testimonial.scrollIntoView({ behavior: "smooth" } - 50);
  };
  useEffect(()=>{
    const parent = document.querySelector('.testimonial-outer')
    const sections = ['ishant-dahiya', 'priyanka-sahni', 'kabir-dhawan', 'vikram-singh', 'aditi', 'anusha-sharma'];
    const handleActiveSection = () => {
      const scrollPosition = parent.scrollTop;
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section && section.offsetTop <= scrollPosition + 200){
          setSelectedT(sections[i]);
          break;
        }
      }
    };
    const setTestimonial = () => {
      const scrollPosition = parent.scrollTop;
      const offsetAdjustment = 10; // Set this to your desired offset from the top
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section && section.offsetTop <= scrollPosition + 300) {
          // Calculate the new scroll position to set the section at 100px from the top
          const newScrollPosition = section.offsetTop - offsetAdjustment;
          parent.scrollTo({ top: newScrollPosition, behavior: "smooth" }); // Smooth scrolling
          break;
        }
      }
    };
    parent.addEventListener('scroll',handleActiveSection)
    parent.addEventListener("scrollend", setTestimonial)
    return ()=> {
      parent.removeEventListener('scroll',handleActiveSection)
      parent.removeEventListener("scrollend", setTestimonial)
    }
  },[])
  
  return (
    <Outer>
      <div className="outer" ref={component}>
        <div className="testimonials-section app-padding" ref={pinSlider}>
          <SectionTitle center>
            Hear what our <span>thrilled users</span> have to say about their
            rewarding experiences with our app!
          </SectionTitle>
          <div className="testimonials-con">
            <RadialBg
              className="radial-bg"
              size={400}
              style={{
                position: "absolute",
                right: "10%",
                bottom: "20%",
              }}
            />
            <div className="people">
              {testimonials.map((t, i) => (
                <UserCard
                  className="user-icon"
                  img={t.img}
                  isActive={t.id === selectedT}
                  key={i}
                  onClick={() => clickTestimonial(i,t.id)}
                />
              ))}
            </div>
      <img src={StarImg} alt="star" height={30} width={30}  style={{position: "absolute", top: "12vw", right: "16vw",  objectFit: "cover",}} />
            <div className="testimonial-outer">
              {testimonials.map((t, i) => (
                <div className={`testimonial ${selectedT === t?.id ? 'visible' : ''}`} key={i} id={t?.id} >
                  <div className="testimonial-top">
                    <div className="user-info">
                      <img src={t.img} alt="user" />
                      <div>
                        <p className="user-name">{t.name}</p>
                        <p className="tag">{t.tag}</p>
                      </div>
                    </div>
                    <div className="t-stars" style={{display:"flex", alignItems:'center'}}>
                      <span className="t-stars">
                        {Array(t.stars)
                          .fill(1)
                          .map((s, i) => (
                            <IoStar size={19.5} key={i} color="gold" />
                          ))}
                      </span>
                      <span className="t-star-count">
                        {t.stars.toFixed(1)}
                      </span>
                    </div>
                  </div>
                  <p className="t-desc">"{t.description}"</p>
                </div>
              ))}
            </div>
      <img src={StarImg} alt="star" height={30} width={30}  style={{position: "absolute", bottom: "10vw", left: "42%",  objectFit: "cover",}} />
          </div>
        </div>
      </div>
      <SectionTitle center className="swiper-title app-padding">
        Hear what our <span>thrilled users</span> have to say about
        their rewarding experiences with our app!
      </SectionTitle>
      <Slider
        autoplay
        dots
        infinite
        speed={500}
        autoplaySpeed={3000}
        slidesToShow={1}
        slidesToScroll={1}
        className="swiper-section app-padding"
      >
        {testimonials.map((t, i) => (
          <div className="testimonial-con app-padding" key={i}>
            <div className="testimonial-top">
              <span className="t-stars">
                {Array(t.stars)
                  .fill(1)
                  .map((s, i) => (
                    <IoStar key={i} color="gold" />
                  ))}
              </span>
              <span className="t-star-count"  style={{ opacity: ".6" }}>{t.stars.toFixed(1)}</span>
            </div>
            <p className="t-desc">"{t.description}"</p>

            <UserCard
              className="user-icon"
              img={t.img}
              isActive={false}
              name={t.name}
              tag={t.tag}
              // onClick={() => clickTestimonial(i)}
            />
          </div>
        ))}
      </Slider>
    </Outer>
  );
};

const Outer = styled.div`
@keyframes fadeIn {
  0% {
    opacity: 0.03; /* Start at low opacity */
  }
  20% {
    opacity: 0.06;
  }
  40% {
    opacity: 0.09;
  }
  60% {
    opacity: 0.1;
  }
  100% {
    opacity: 1; /* End with full opacity */
  }
}
  .swiper-section,
  .swiper-title {
    display: none;
  }
  .swiper-section {
    display: none;
    padding: 60px 0 70px;
    text-align: center;
    .testimonial-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin-bottom: 20px;
      .t-stars {
        display: flex;
        gap: 4px;
        margin-right: 15px;
      }
    }
    .t-desc {
      font-size: 21px;
      font-weight: 300;
      font-style: italic;
      font-family:lexend;
      line-height: 35px;
      letter-spacing: 0.6px;
    }
    .user-icon {
      margin: auto;
      width: fit-content;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      gap: 10px;
      text-align: left;
    }
  }
  .testimonials-section {
    padding-bottom: 104px;
    position: relative;

    .testimonials-con {
      padding: 50px 0;
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-auto-rows: 100%;
      .people {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: hidden;
      }

      .testimonial-outer {
        height: 100%;
        position: relative;
        max-height:400px;
        overflow-y: auto;
        scrollbar-width: none;
        .testimonial {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 5%;
          gap: 10px;
          background: transparent;
          left: 0;
          bottom: 0;
          width: 100%;
          margin:2rem 0;
          opacity: 0.09; /* Initial state */
  transition: opacity 1s ease-in-out;
  &.visible {
    opacity: 1;
  }
  animation: fadeIn 1s ease-in-out;
  
          .testimonial-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            font-size: 20px;
            .user-info {
              display: flex;
              align-items: center;
              gap: 20px;
              margin-bottom: 40px;
              img {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                border: 1px solid lime;
              }
              .user-name {
                font-family: Lexend;
                font-size: 22px;
                font-weight: 400;
                line-height: 31px;
              }
              .tag {
                color: rgba(255, 255, 255, 0.6);
                font-family: Lexend;
                font-size: 18px;
                font-weight: 300;
                line-height: 32px;
              }
            }
            .t-stars {
              display: flex;
              gap: 4px;
              margin-right: 15px;
            }
          }
          .t-desc {
            margin-top: 20px;
            font-style: italic;
            font-family:lexend;
            font-size: 23px;
            font-weight: 300;
            line-height: 35px;
            letter-spacing: 0.6px;
            opacity: 0.9;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .outer {
      display: none;
    }
    .swiper-section,
    .swiper-title {
      display: block;
    }
  }
  @media screen and (max-width: 1260px) {
    .testimonials-section{.testimonials-con {.testimonial-outer{
      max-height: 80vw;
    }}
}  }
`;

export default TestimonialSection;

