import React from 'react'
import styled from 'styled-components'

const CareerPage = () => {
  return (
    <Container>
  <iframe src="https://app.pyjamahr.com/careers?company=
LeadMint Technologies&company_uuid=ECE0EEE275
&isHeaderVisible=true&is_careers_page=true"
frameborder="0" scrolling="yes" seamless="seamless"
style={{display:"block", width:"100%", height:"100%"}}></iframe>
    </Container>
  )
}

export default CareerPage

const Container = styled.div`
width: 100%;
height: 100vh;
`