import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import {IoLogoGooglePlaystore,IoPeopleOutline,} from "react-icons/io5";
import { ReactComponent as ArrowRight } from "../assets/icons/ArrowRight.svg";
import { TbCashBanknote } from "react-icons/tb";
import { PiStarFourFill } from "react-icons/pi";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
// import BlogSection from "../sections/BlogSection.jsx";

import NavBar from "../components/NavBar";
import colors from "../config/colors";
import logo from "../assets/icons/loftime.webp";
import tickets from "../assets/homepage/badges.webp";
import AppBtn from "../components/AppBtn";
import StatCard from "../components/StatCard.jsx";
import SectionTitle from "../components/SectionTitle";
import AppCard from "../components/AppCard";

import ribbonLeft from "../assets/commonImg/ribbon-left.webp";
import ribbonRight from "../assets/commonImg/ribbon-right.webp";
import gridImg from "../assets/commonImg/hero-grid.webp";
import Footer from "../components/Footer";
import JoinUsSection from "../sections/JoinUsSection";
import MessageSection from "../sections/MessageSection";
import TestimonialSection from "../sections/TestimonialSection";
import CommunityTrusteby from "../components/CommunityTrusteby.js";

import { homePageAppList, communityDataHomePage } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const CenteredTestimonialSection = styled(TestimonialSection)`
  margin-top: 1vh; /* You can adjust this value as needed */
`;

const HomePage = () => {
  const [openedApp, setOpenedApp] = useState(null);
  const navigate = useNavigate();
useEffect(()=>{
window.scrollTo(0,0)
},[])

  return (
    <Container>
      <div className="hero-section" id="home">
      <NavBar />
        <img className="ribbon-left" src={ribbonLeft} alt="ribbon-left" />
        <div className="hero-content app-padding">
          <div className="hero-left">
            <SectionTitle isSabo className="title">
              WELCOME TO <img rel="preload" src={logo} alt="logo" /> WHERE FUN MEETS CASH 💸
            </SectionTitle>
            <p className="subtitle">
              Experience the thrill of personalized rewards with LoftLime. Dive
              into our vast collection of apps, find your favorite, and kick off
              your own unique journey to earnings! 🎉💰
            </p>
            <div className="home-btns cursor">
              <AppBtn onClick={()=>navigate('/products')}>
                <span>Start Earning</span>
              </AppBtn>
              <div className="btn-title cursor" onClick={()=>window.open('https://loftlime.com/partnerwithus', '_blank')}>
                <span>Partner with Us</span> <ArrowRight />
              </div>
            </div>
          </div>

          <div className="hero-right">
            <img src={tickets} alt="tickets" />
          </div>
        </div>
        <img className="ribbon-right" src={ribbonRight} alt="ribbon-right" />
      </div>

      <div className="top-stats app-padding animate-in">
        <StatCard
          Icon={IoLogoGooglePlaystore}
          title="10Mn+"
          subtitle="Combined Playstore downloads"
        />
        <StatCard
          Icon={TbCashBanknote}
          title="₹20Mn+"
          subtitle="Total cash rewards distributed"
        />
        <StatCard
          Icon={IoPeopleOutline}
          title="300+"
          subtitle="Companies advertised"
        />
      </div>

      <div className="ribbons">
        <Marquee className="green-ribbon" speed={50} direction="right">
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
        </Marquee>
        <Marquee className="yellow-ribbon" speed={50}>
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
          <span>Lime Up Your Rewards</span>
          <PiStarFourFill />
        </Marquee>
      </div>

      <div className="mob-slider-section app-padding">
      <SectionTitle isSabo={true} center={true}>
          The <img src={logo} alt="logo" /> App Inventory
        </SectionTitle>
        <div className="info">
          Play, Engage, and Conquer Tasks: Once you've selected your desired
          app, it's time to embark on an exhilarating journey of task
          completion. Immerse yourself in the app's captivating features,
          challenge yourself with puzzles, achieve milestones & start your
          unique journey to earnings.
        </div>
        <Slider
				autoplay
				dots={false}
				infinite
				speed={500}
				autoplaySpeed={3000}
				slidesToShow={1}
				slidesToScroll={1}
        arrows={false}
				className="swiper-section app-padding"
			>
				{homePageAppList.map((app, i) => (
					<AppCard
							key={i}
							isActive={i === openedApp}
							appName={app.appName}
							appDesc={app.appDesc}
							appImg={app.appImg}
							iconImg={app.iconImg}
							bgColor={app.bgColor}
							link={app.link}
							onHover={() => setOpenedApp(i)}
							onleave={() => setOpenedApp(null)}
							btn={app.btn}
						/>
				))}
			</Slider>
      </div>


      <div className="apps-section app-padding">
        <SectionTitle isSabo={true} center={true}>
          The <img src={logo} alt="logo" /> App Inventory
        </SectionTitle>
        <div className="info">
          Play, Engage, and Conquer Tasks: Once you've selected your desired
          app, it's time to embark on an exhilarating journey of task
          completion. Immerse yourself in the app's captivating features,
          challenge yourself with puzzles, achieve milestones & start your
          unique journey to earnings.
        </div>
        <div className="app-padding apps">
          {homePageAppList.map((app, i) => (
            <AppCard
              key={i}
              to={app.id}
              // isActive={i === openedApp}
              appName={app.appName}
              appDesc={app.appDesc}
              appImg={app.appImg}
              iconImg={app.iconImg}
              bgColor={app.bgColor}
              link={app.link}
              // onHover={() => setOpenedApp(i)}
              // onleave={() => setOpenedApp(null)}
              btn={app.btn}
            />
          ))}
        </div>
      </div>
      <CenteredTestimonialSection />
    <div className="community-section app-padding">
	  <SectionTitle center>We are a community of <span>10Million+</span> users</SectionTitle>
	  <CommunityTrusteby data={communityDataHomePage}/>
	  </div>

      <JoinUsSection />

      {/* <BlogSection /> */}

      <MessageSection />

      <Footer/>
    </Container>
  );
};

const Container = styled.div`
  background: ${colors.black};
  min-height: 100vh;
  height: fit-content;
  color: ${colors.white};
  overflow: hidden;
  .hero-section {
    background: url(${gridImg});
    background-size: 200px;
    background-repeat: repeat;
    position: relative;
    nav {
      position: absolute;
      width: 100%;
    }
    .ribbon-left,
    .ribbon-right {
      position: absolute;
    }
    .ribbon-left {
      left: 0;
      top: 40%;
      height: 190px;
    }
    .ribbon-right {
      right: 0;
      top: 70%;
      height: 190px;
    }
    .hero-content {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-auto-rows: 100%;
      height: 600px;
      column-gap: 50px;
      row-gap: 10px;
      position: relative;

      .hero-left {
        z-index: 1;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        .title {
          font-size: 40px;
          line-height: 52px;
          img {
            height: 36px;
          }
        }
        .subtitle {
          font-weight: 300;
          line-height: 25px;
          letter-spacing: 0.2px;
          opacity: 0.7;
        }

        .home-btns {
          display: flex;
          align-items: center;
          gap: 30px;
          margin-top: 10px;
          .btn-title {
            text-align: center;
            font-family: Lexend;
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.25px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        span {
          font-weight: 600;
        }
      }
      .hero-right {
        display: flex;
        justify-content: center;
        img {
          height: 700px;
        }
      }
    }
  }
  .top-stats {
    display: grid;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 60px;
    grid-template-columns: repeat(auto-fit, 200px);
  }
  .ribbons {
    height: fit-content;
    position: relative;
    height: 250px;
    display: flex;
    padding-top: 80px;
    overflow: hidden;
    & > * {
      position: absolute;
      padding: 12px 0;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      * {
        margin: 0 4px;
      }
    }
    .yellow-ribbon {
      transform: rotate(3deg);
      background: ${colors.yellow};
      color: ${colors.black};
    }
    .green-ribbon {
      transform: rotate(-3deg);
      background: ${colors.green};
    }
  }
  .mob-slider-section{
    display: none;
  }

  .apps-section {
    padding: 90px 0 84px 0;
    .info {
      padding-top:12px;
      width: 68vw;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.7;
    }
    .apps {
      padding: 70px 90px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px 60px;
    }
  }
  .swiper-section {
    display: none;
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .about-section {
    padding-top: 40px;
    padding-bottom: 100px;
    .about-cards {
      width: 70%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      .about-card {
        border-radius: 15px;
        padding: 25px;
        min-width: 220px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 6px;
        .about-title {
          font-weight: 200;
          font-size: 14px;
          letter-spacing: 0.3px;
        }
        .about-subtext {
          font-size: 25px;
          font-weight: 600;
        }
      }
    }
  }

  .section-desc {
    line-height: 27px;
    font-weight: 200;
    font-size: 17px;
    letter-spacing: 0.5px;
    width: 70%;
    margin: 0 auto 30px;
    text-align: center;
    opacity: 0.8;
  }


  @media screen and (max-width: 1000px) {
    .hero-section {
      .hero-content {
        .hero-right {
          img {
            height: 600px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .hero-section {
      .ribbon-right {
        top: 52vw;
        height: 14vw;
      }
      .ribbon-left {
        top: 68vw;
        height: 20vw;
      }
      .hero-content {
        display: flex;
        flex-direction: column-reverse;
        height: fit-content;
        row-gap: -30px;

        .hero-right {
          display: flex;
          justify-content: center;
          img {
            height: 80vw;
          }
        }
      }
    }

    .top-stats {
      padding-top: 50px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .swiper-section {
      display: block;
    }

    .apps-section {
			display: none;
		}
    .mob-slider-section{
      display: block;
      div{
        text-align: center;
      }
    }
    .about-section {
      .about-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        width: 90%;
        .about-card {
          width: 100%;
          min-width: fit-content;
        }
      }
    }
    .section-desc {
      width: 100%;
    }
    }
    
  
`;

export default HomePage;
