import React, { useEffect } from "react";
import { styled } from "styled-components";

import NavBar from "../components/NavBar";
import SectionTitle from "../components/SectionTitle";
import colors from "../config/colors";
import Footer from "../components/Footer";
import logo from "../assets/icons/loftime.webp";
import CardBgColor from "../components/CardBgColor";
import UserStory from "../components/UserStory";
import gridImg from "../assets/commonImg/hero-grid.webp";
import greenGradBg from "../assets/commonImg/greenGradientBG.webp";
import ribbon from "../assets/commonImg/triangleSQuareRibbon.webp";
import greyRing from "../assets/commonImg/greyRing.webp";
import { RewardSteps, rewardOptionList, successStoriesData} from "../utils/constants";
import RewardPartners from "../components/RewardPartners";


const RewardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <NavBar />
      <div className="topWrapper">
        <img src={greenGradBg} alt="greenGradBg" style={{ position: "absolute", top: "16%", right: "16%", width: "50%", height: "50%", zIndex: 0,
          }}
        />
        <img src={ribbon} alt="ribbon" style={{ position: "absolute", top: "16%",width: "auto",height: "12vw", right: "-4.4%", zIndex: 0,
          }}
        />
        <img
          src={greyRing}
          alt="greyRing"
          style={{ position: "absolute",  width: "auto",
            height: "10vw", top: "16%", left:'-2vw', zIndex: 0 }}
        />
        <div className="top-section app-padding">
          <SectionTitle isSabo center>
            Your Rewards <br />
            <span style={{ fontFamily: "inherit" }}>Your Choice</span>
          </SectionTitle>

          <p className="subtext">
            At LoftLime, we believe in rewarding every moment you spend with our
            apps. That's why we've designed a rewards program that lets you earn
            while you play. From completing tasks to achieving milestones, every
            action takes you one step closer to your rewards.
          </p>
        </div>
      </div>
      <RewardPartners/>
      <div className="steps-section app-padding">
        <SectionTitle className="title" center isSabo>
          Getting rewarded with <img src={logo} alt="logo" />
          <br /> is as simple as 1..2..3.
        </SectionTitle>

        <div className="steps">
          {RewardSteps?.map((step, i) => (
            <CardBgColor className="step-card" key={i}>
              <p className="step-no">STEP #{`${i + 1}`}</p>
              <p className="step-info">{step.info}</p>
              <img src={step.img} alt="step1" />
            </CardBgColor>
          ))}
        </div>
      </div>

      <div className="how-section app-padding">
        <SectionTitle center>
          How you get <span>Rewarded</span>
        </SectionTitle>

        <p className="subtitle">
          Our rewards come in all shapes and sizes. You can redeem your earnings
          for cash, choose from a wide range of gift cards, or use your points
          to grab exclusive discounts on your favorite brands.
        </p>

        <div className="how-cards">
          {rewardOptionList.map((h, i) => (
            <CardBgColor key={i} className="how-card">
              <img src={h.img} alt="how-img" />
              <div className="how-right">
                <p className="hc-title">{h.title}</p>
                <p className="hc-subtitle">{h.subtitle}</p>
              </div>
            </CardBgColor>
          ))}
        </div>
      </div>

      <div className="success-section app-padding">
        <SectionTitle center>
          Success Stories of <span>Our Users</span>
        </SectionTitle>

        <p className="subtitle">
          Hear what our users have to say about their rewarding experiences with
          our apps!
        </p>

        <div className="user-stories">
          {successStoriesData.map((u, i) => (
            <UserStory key={i} img={u.img} name={u.name} story={u.story} />
          ))}
        </div>
        <p className="moreStories">and many more...</p>
      </div>

      {/* <MessageSection /> */}

      <Footer/>
    </Container>
  );
};

const Container = styled.div`
  background: ${colors.black};
  min-height: 100vh;
  color: ${colors.white};
  overflow: hidden;
  .topWrapper {
    background: url(${gridImg});
    background-size: 200px;
    background-repeat: repeat;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .subtitle {
    text-align: center;
    opacity: 0.7;
    font-size: 20px;
    font-weight: 200;
    line-height: 25px;
    letter-spacing: 0.4px;
    margin: 30px auto 40px;
    width: 80%;
  }

  .top-section {
    min-height: 400px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    flex-direction: column;
    padding: 50px 0;
    p {
      width: 80%;
      margin: 0 10%;
      &:first-of-type {
        font-size: 47px;
      }
    }

    .subtext {
      line-height: 30px;
      font-size: 20px;
      font-weight: 200;
    }
    .subtextNew {
      line-height: 30px;
      font-size: 20px;
      font-weight: 200;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  .steps-section {
    padding-bottom: 154px;
    .title {
      line-height: 76px;
    }
    .steps {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      /* grid-auto-rows: 200px; */
      gap: 25px;
      margin: 60px 0 50px;

      & > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .step-no {
          font-family: Sabo;
          font-size: 20px;
          color: ${colors.yellow};
          padding:40px 40px 10px 40px;
        }
        .step-info {
          color: ${colors.white};
          font-size: 24px;
          margin-top: 10px;
          letter-spacing: 0.2px;
          font-family: lexend;
          padding:2px 40px 40px 40px;
        }
      }
    }
  }
  .how-section {
    padding-bottom: 154px;

    .how-cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 30px;
      .how-card {
        padding: 40px;
        display: flex;
        display:inline-block;
        align-items: center;
        gap: 40px;

        &:nth-child(3) {
        }
        img {
          width: 100px;
          height: auto;
        }
        .how-right {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .hc-title {
            font-size: 20px;
            margin-bottom: 10px;
          }
          .hc-subtitle {
            opacity: 0.7;
            color: #fff;
            font-family: Lexend;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 32px;
          }
        }
      }
    }
  }
  .success-section {
    padding-bottom: 154px;
    .subtitle {
      opacity: 0.7;
    }
    .user-stories {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;
    }
    .moreStories {
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.6;
	  padding-top: 60px;
    }
  }

  @media screen and (max-width: 550px) {
    .how-section {
      .how-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 30px;

        .how-card {
          padding: 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;

          img {
            width: 80px;
          }
        }
      }
    }
  }
`;

export default RewardPage;
