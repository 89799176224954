import gsap from 'gsap';

export const animate = (rootRef, targets,direction) => {
	let ctx = gsap.context(() => {
		gsap.defaults({ duration:.5 });
		if (direction === 'leftToRight') {
            gsap.fromTo(
                targets,
                { translateX: -105, opacity: 0 },
                { translateX: 0, opacity: 1 }
            );
        } else if (direction === 'rightToLeft') {
            gsap.fromTo(
                targets,
                { translateX: 35, opacity: 0 },
                { translateX: 0, opacity: 1 }
            );
        } else {
		gsap.fromTo(
			targets,
			{ translateY: 35, opacity: 0 },
			{ scrollTrigger: targets, translateY: 0, opacity: 1 }
		);}
	}, rootRef);

	return ctx;
};

export const fixedScrollApps = (rootRef, targets) => {
	let ctx = gsap.context(() => {
		let sections = gsap.utils.toArray(targets);

		gsap.set(
			sections.filter((s, i) => i !== 0),
			{
				opacity: 0,
			}
		);

		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: rootRef.current,
				scrub: 1,
				snap: 1 / (sections.length - 1),
				pin: true,
				start: 'top 20px',
				end: 'bottom 0',
				// markers: true,
			},
		});

		sections.forEach((section, index) => {
			tl.to(section, {
				opacity: 1,
			});
		});
	}, rootRef);

	return ctx;
};
