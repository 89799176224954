import React from "react";
import { styled } from "styled-components";
import {ReactComponent as LinkedIncon} from "../assets/icons/linkedInBlue.svg";

const TeamMember = ({ img, name, level,linkedin }) => {
  return (
    <Container onClick={()=>window.open(linkedin, '_blank')}>
      <div className="image">
        <img src={img} alt="team_member" />
        <LinkedIncon className="linkedinicon" />
      </div>
      <div className="tm-bottom">
        <p className="name">{name}</p>
        <p className="level">{level}</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
 
  ${'' /* display: grid;  1
  grid-template-columns: 1fr; 2
  grid-auto-rows: 180px auto; 3
  justify-content: center;    4  */}
  max-width: 300px;
  border-radius: 10px;
  ${'' /* border: 1px solid red; */}
  ${'' /* overflow: hidden;    */}
  .image {
    ${'' /* height:219px;
    width: 219px; */}
    min-height: 220px;
    max-height: 220px;
    position: relative;
    overflow: hidden; 5
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 10px;
    &:hover img {
      transform: scale(1.1);
      border-radius: 10px;
    }
    &:hover .linkedinicon {
      transform: scale(1);
    }
    img {
      border-radius: 10px;
      transition: all 0.6s;
    }
    .linkedinicon {
      position: absolute;
      bottom: 10px;
      right:4%;
      width: 40px;
      height: 40px;
      color: #fff;
      padding: 5px;
      font-size: 12px;
      transform: scale(0);
      transition: all .6s;
     
    }
  }
  .tm-bottom {
    padding-top: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    .name {
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .level {
      color: #9f9ea1;
      text-align: center;
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
  @media screen and (max-width: 1025px) {
    .image{
      .linkedinicon {
      position: absolute;
      bottom: 10px;
      right:14%;
      width: 40px;
      height: 40px;
      color: #fff;
      padding: 5px;
      font-size: 12px;
      transform: scale(0);
      transition: all .6s;
     
    }}
  }
`;

export default TeamMember;
