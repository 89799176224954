import React from 'react'
import styled from 'styled-components'
import  NYKAA from "../assets/commonImg/partnersImg/nykaa.webp";
import FLIPKART from "../assets/rewardpage/flipkart.webp";
import UPI from "../assets/rewardpage/upi.webp";
import AMAZON from "../assets/rewardpage/amazon.webp";
import SWIGGY from "../assets/rewardpage/swiigy.webp";
import MYNTRA from "../assets/rewardpage/myntra.webp"
import ZOMATO from "../assets/rewardpage/zomato.webp"
import GOOGLEPLAY from "../assets/rewardpage/googleplay.webp"

const RewardPartners = () => {
  return (
<Container className="partnerSection app-padding">
        <h5>We have worked with</h5>
        <div className="partner-list">
        <img src={NYKAA} alt="Unacademy" />
        <img src={UPI} alt="FiMoney" />
        <img src={FLIPKART} alt="Nykaa" />
        <img src={AMAZON} alt="BajajFin" />
        <img src={GOOGLEPLAY} alt="Bmarkets" />
        <img src={SWIGGY} alt="Adani" />
        <img src={MYNTRA} alt="Yolo" />
        <img src={ZOMATO} alt="Paytm" />
      </div>
    </Container>
  )
}

export default RewardPartners
const Container = styled.div`
margin-bottom:154px;
    h5 {
      padding:0 0 32px 0;
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.6;
    }
    .partner-list {
      display: flex;
      gap: 34px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img{
        border-radius: 10px;
        max-height: 34px;
      }
    }
    @media screen and (max-width: 1350px) {
    .partner-list {
      img{
        height: 36px;
      }
    }
  }
  @media screen and (max-width: 778px) {
    .partner-list {
      img{
        height: 26px;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .partner-list {
      img{
        height: 22px;
      }
    }
  }
`