import React from "react";
import styled from "styled-components";
import rocket from "../assets/commonImg/mrRocket.webp";
import  Unacademy from "../assets/commonImg/partnersImg/unacademy.webp";
import  Paytm from "../assets/commonImg/partnersImg/paytm.webp";
import  BajajFin from "../assets/commonImg/partnersImg/bajajfinsev.webp";
import  Adani from "../assets/commonImg/partnersImg/adani.webp";
import  Zupee from "../assets/commonImg/partnersImg/zupee.webp";
import  Yolo from "../assets/commonImg/partnersImg/yolo.webp";
import  Nykaa from "../assets/commonImg/partnersImg/nykaa.webp";
import  Bmarkets from "../assets/commonImg/partnersImg/bmarkets.webp";
import  FiMoney from "../assets/commonImg/partnersImg/Fi.webp";


const CommunityTrusteby = ({ data }) => {
  return (
    <Container>
      <div className="cardSection">
        <img src={rocket} alt="rocket" className="rocket" />
        {data?.map((item) => (
          <div key={item?.title} className="card">
            <h3 className="title">{item?.title}</h3>
            <span className="desc">{item?.description}</span>
          </div>
        ))}
      </div>
      <div className="partnerSection app-padding">
        <h5>We have worked with</h5>
        <div className="partner-list">
        <img src={Unacademy} alt="Unacademy" />
        <img src={FiMoney} alt="FiMoney" />
        <img src={Nykaa} alt="Nykaa" />
        <img src={BajajFin} alt="BajajFin" />
        <img src={Bmarkets} alt="Bmarkets" />
        <img src={Adani} alt="Adani" />
        <img src={Yolo} alt="Yolo" />
        <img src={Paytm} alt="Paytm" />
        <img src={Zupee} alt="Zupee" />

        </div>
      </div>
    </Container>
  );
};

export default CommunityTrusteby;
const Container = styled.div`
padding-bottom: 154px;
  .rocket {
    position: absolute;
    left: 34%;
    top: 30%;
    z-index: 1;
  }
  .cardSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 60px;
    padding: 50px 50px 0 50px;
    position: relative;
    width: fit-content;
    margin: auto;
    overflow: hidden;

    .card {
      height: 100%;
      min-width: 550px;
      margin: auto;
      padding: 60px 50px;
      border-radius: 20px;
      position: relative;
      background: linear-gradient(to bottom, #1f1f1f, #17171740 25%);
      box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(20px);
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 20px;
        padding: 1.5px;
        background: linear-gradient(to top, #171717 15%, #3f3f3f);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
      .title {
        color: #fff;
        font-family: Sabo;
        font-size: 80px;
        font-style: normal;
        font-weight: 400;
        line-height: 100px;
      }
      .desc {
        color: #f7cd62;
        font-family: Sabo;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 52.289px;
      }
      &:nth-child(4) {
        z-index: 2;
      }
    }
  }
  .partnerSection {
    h5 {
      padding: 80px 0 32px 0;
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.6;
    }
    .partner-list {
      display: flex;
      gap: 34px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img{
        border-radius: 10px;
        max-height: 38px;
      }
    }
  }
  @media screen and (max-width: 1350px) {
    .rocket{
      left:28vw;
      top: 26%;
      width: 30%;
    }
    .cardSection {
      .card {
        width: 100%;
        min-width: 34vw;
        padding: 20px;
        margin-bottom: 20px;
        .title {
          font-size: 40px;
          line-height: 50px;
        }
        .desc {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .partner-list {
      img{
        height: 36px;
      }
    }
  }
  @media screen and (max-width: 778px) {
    .rocket {
      left:18vw;
      z-index: 1;
      width:50%;
    }
    .cardSection {
      .card {
        width: 100%;
        min-width:30vw;
        margin-bottom: 20px;
        .title {
          font-size: 32px;
          line-height: 50px;
        }
        .desc {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
    .partner-list {
      img{
        height: 26px;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .rocket {
      left: 0;
      z-index: 0;
      width:100vw;
      height:auto;
    }
    .cardSection {
      display: block;
      .card {
        width: 100%;
        min-width: 44vw;
        padding: 20px;
        margin-bottom: 20px;
        .title {
          font-size: 40px;
          line-height: 50px;
        }
        .desc {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }
    .partner-list {
      img{
        height: 22px;
      }
    }
  }
`;
