import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import {ReactComponent as LinkedinIcon} from "../assets/icons/linkedinIcon.svg"
import {ReactComponent as InstaIcon} from "../assets/icons/instaIcon.svg"
import {ReactComponent as ArrowRight} from "../assets/icons/ArrowRight.svg"

import leadmint from '../assets/icons/leadmint.webp';
import loftlime from '../assets/icons/loftime.webp';
import CardBgColor from './CardBgColor';
import AppBtnAlt from './AppBtnAlt';
import Popup from './Popup';

const checkEmail = (email) => {
	const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
	return regEx.test(email);
}

const Footer = ({ style }) => {
const [email, setEmail] = React.useState('');
const [loading, setLoading] = React.useState(false);
const [emailFormatError, setEmailFormatError] = React.useState(false);
const [showPopup, setShowPopup] = React.useState(false);
const [subscribeResponse, setSubscribeResponse] = React.useState(null)
const handleSubscribe = async() => {
	setLoading(true);
	if(checkEmail(email)){
		// alert('Sorry, subscribing to newsletter is disabled for now')
		// setLoading(false);
		// setEmailFormatError(false);
		try{const response = await(await fetch('https://api.loftlime.com/subscribeletter',{
			method:'POST',
			headers:{
				'Content-Type':'application/json'
			},
			body: JSON.stringify({email})
		})).json();
		setSubscribeResponse(response);
		setEmailFormatError(false);
		setEmail('');
		setShowPopup(true)
		setLoading(false);
	} catch(err){
		setSubscribeResponse({error:"Sorry something went wrong, Please try again!!!"});
		setShowPopup(true)
		setLoading(false);
	}
	}else{
		setEmailFormatError(true);
		setLoading(false);
	}
}
	return (
		<Container className="app-padding" style={style}>
		{showPopup && <Popup connectForm={false} setShowPopup={setShowPopup} message={subscribeResponse?.message || subscribeResponse?.error} error={subscribeResponse?.error && true} />}
			<div className="footer-top">
				<div className="logos">
					<img src={loftlime} alt="loftlime" />

					<div className="leadmint">
						<span>A part of</span>
						<img src={leadmint} alt="leadmint logo" />
					</div>
				</div>

				<div className="links-con">
					<div className="link-column">
						<span className="link-h">QUICK LINKS</span>
						<span className="link" onClick={()=>window.scrollTo(0,0)}><Link to="/">  Home</Link></span>
	  					<span className="link" onClick={()=>window.scrollTo(0,0)}><Link to='/products'> Products </Link></span>
						<span className="link" onClick={()=>window.scrollTo(0,0)}><Link to='/rewards'>Rewards</Link></span>
						<span className="link" onClick={()=>window.scrollTo(0,0)}><Link to='/about'>About Us</Link></span>
						{/* <a href="#meet-loftlime" className="link">
                         Blog
                        </a> */}
					    {/* <span className="link">Blog</span> */}
					</div>
					<div className="link-column">
						<span className="link-h">WORK</span>
						<span className="link"  onClick={()=>window.open('https://loftlime.com/partnerwithus', '_blank', 'noopener,noreferrer')}>Partnership</span>
						<span className="link"  onClick={()=>window.open('https://loftlime.com/publishwithus', '_blank', 'noopener,noreferrer')}>Publish With Us</span>
						<span className="link"  onClick={()=>window.open('https://loftlime.com/career', '_blank', 'noopener,noreferrer')}>Careers</span>
						{/* <span className="link">Contact Us</span> */}
						{/* <span className="link">Contact</span> */}
					</div>
					<div className="link-column">
						<span className="link-h">SOCIAL MEDIA</span>
						{/* <span className="link">Facebook</span> */}
						<div className='logo-sec'>
						<a href="https://www.instagram.com/loftlime/" className="link" target='_blank' rel="noreferrer">
						<InstaIcon className='instaIcon'/>
                        </a>
						<a href="https://www.linkedin.com/company/leadminthq" className="link" target='_blank' rel="noreferrer">
                         <LinkedinIcon className='linkedinIcon'/>
                        </a>
						</div>
						<Link to="/youtuber" className="link">Youtuber's</Link>
						{/* <span className="link">Twitter</span> */}
						{/* <span className="link">Youtube</span> */}
					</div>
				</div>

				<div className="newsletter-con">
					<span className='title'>Subscribe to our newsletter</span>
					<CardBgColor  className="input-con">
						<input
							type="email"
							name="email"
							id="email"
							placeholder="Your email ID"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							autoComplete="off"
						/>
					</CardBgColor>
					<span className={`error ${emailFormatError && 'show'}`}>Kindly enter a valid email</span>
					<AppBtnAlt className={loading && 'loading'} onClick={handleSubscribe} style={{fontSize:'15px'}}>Subscribe</AppBtnAlt>
					<span style={{color: "#666", fontWeight:400, lineHeight:'26px', marginTop:"30px"}}>Have any other queries? <br/> Mail us at <Link  to="mailto:contact@loftlime.com">
					contact@loftlime.com <ArrowRight style={{paddingTop:'4px'}} />
				</Link></span>
				</div>
			</div>
			<div className="footer-bottom">
				<span className="dim">
					&copy; {new Date().getFullYear()} LeadMint Technologies Pvt Ltd. All Rights
					Reserved.
				</span>

				<div className='policySection'>
					<Link to="/privacypolicy" target='_blank' className="dim">
						Privacy Policy
					</Link>

					<div className="separator dim"></div>

					<Link to="/termsandconditions" target='_blank' className="dim">
						Terms & Conditions
					</Link>
				</div>
			</div>
		</Container>
	);
};

const Container = styled.footer`
	.footer-top {
		padding-bottom: 25px;
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		gap: 20px;

		.logos {
			display: flex;
			flex-direction: column;
			gap: 25px;
			justify-content: space-between;

			img {
				width: 65%;
				height: auto;
			}
			.leadmint {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}

		.links-con {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
			gap: 20px;
			.link-column {
				display: flex;
				flex-direction: column;
				gap: 10px;

				* {
					font-size: 14px;
				}
				.link-h {
					margin-bottom: 13px;
					opacity: 0.4;
				}
				.link {
					font-weight: 300;
					font-size: 15px;
					letter-spacing: 1px;
					cursor: pointer;
				}
				
			&:nth-child(3){
				.link{
					opacity:1;
				}
				.logo-sec{
					display: flex;
					gap: 10px;
					opacity:1;
					.instaIcon, .linkedinIcon{
					transition: all 0.3s ease;
					cursor: pointer;
					&:hover{
						transform: scale(1.1);
					}
					}
				}
			}
			}
		}

		.newsletter-con {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.title{
				${'' /* opacity: 0.4; */}
			}
			.input-con {
				padding: 10px;
				border-radius: 10px;
				position: relative;
				overflow: hidden;
				height: 50px;
				&::before {
					border-radius: 10px;
				}
				input {
					position: absolute;
					borde-radius: 10px;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: transparent;
					border: none;
					padding: 13px 18px;
					color: #fff;
					font-size: inherit;
					&:focus {
						outline: none;
					}
				}
			}
			.error{
					font-size: 14px;
					color: red;
					bottom: 0;
					opacity: 0;
					margin-top: -12px;
					&.show{
						opacity: 1;
					}
				}	
		.loading{
			cursor: not-allowed;
			opacity: 0.6;
			pointer-events: none;
		}
		}
	}

	.footer-bottom {
		padding-top: 45px;
		padding-bottom: 25px;
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
		justify-content: space-between;
		& > * {
			display: flex;
			gap: 8px;
			align-items: center;
		}
		.separator {
			height: 100%;
			width: 1px;
			background: grey;
		}
		.policySection{
			a{
				trasition: all 0.3s ease;
				&:hover{
					color: #fff;
				}
			}
		}
		.dim {
			font-size: 14px;
			color: grey;
			font-weight: 200;
		}
	}

	@media screen and (max-width: 1020px) {
		.footer-top {
			grid-template-columns: 1fr;
			grid-auto-rows: fit-content;
			gap: 50px;
			.logos {
				img {
					width: 150px;
					/* 
					height: auto; */
				}
				.leadmint {
					/* display:flex ;
					flex-direction: column;
					gap: 5px; */
				}
			}
		}
	}
`;

export default Footer;
