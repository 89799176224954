import React from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import { styled } from 'styled-components';

import AppBtn from '../components/AppBtn';
import CardBgColor from '../components/CardBgColor';
import SectionTitle from '../components/SectionTitle';

import bigBall from '../assets/commonImg/bigBlackBall.webp'
import smallBall from '../assets/commonImg/bigBlackBall.webp'
import utubeformPrev from "../assets/youtube/utubeformSmallPrev.webp"
import AppBtnAlt from '../components/AppBtnAlt';
import { useNavigate } from 'react-router-dom';
const cards = [
	{
		title: 'Are you looking for a rewarding platform to showcase your app?',
		subtitle:
			'Join our app inventory and reach a wider audience.',
		btnText: 'Publish with Us',
		btnLink: 'https://loftlime.com/publishwithus',
	},
	{
		title: 'Like what we do? Become a partner and grow with us.',
		subtitle:
			'Explore opportunities for aprtnerships, sponsorship, and collaborations.',
		btnText: 'Partner with Us',
		btnLink: 'https://loftlime.com/partnerwithus',
	},
	{
		title: 'Looking for a rewarding career in the app industry?',
		subtitle:
			'Be a part of our team and lets build a rewarding experience for our users.',
		btnText: 'Join our Team',
		btnLink: "https://www.linkedin.com/company/leadminthq/mycompany/",
	},
]

const JoinUsSection = () => {
	const navigate= useNavigate();
	return (
		<Container className="join-section app-padding">
			<SectionTitle center className="animate-title">
			Join forces with LoftLime & shape the future of  
				<span> innovative advertising solutions.</span>
			</SectionTitle>
			
			<div className="join-cards">
			<img src={smallBall} alt="smallBall" className="smallBall"/>
			<img src={bigBall} alt="bigBall" className="bigBall"/>
				{cards.map((card, i) => (
					<CardBgColor key={i}>
						<p className="join-title">{card.title}</p>
						<p className="join-subtitle">{card.subtitle}</p>
							<AppBtn className='btn' onClick={()=>window.open(card?.btnLink, '_blank')}> 
							<span className='title'>{card.btnText}</span> <ImArrowRight2 />
							</AppBtn>
					</CardBgColor>
				))}
			</div>

		<CardBgColor className='youtubeScreen'>
		<div className='formprev'>
			<img src={utubeformPrev} alt="youtubeForm" /></div>
			<div className='content' style={{zIndex:100}}>
				<h2>Are you a Youtube Creator?</h2>
				<div className='subtitle'>We offer you a chance to <span>earn unlimited money</span> from your youtube videos through promotion  of our apps from our Loftlime Inventory.</div>
				<AppBtnAlt style={{fontSize:'20px'}} onClick={()=>navigate("/youtuber?id=youtubeFORM")}>Know more</AppBtnAlt>
			</div>
		</CardBgColor>
		
		<CardBgColor className='youtubeScreen-mob'>
			<div className='content' style={{zIndex:100}}>
				<h2>Are you a Youtube Creator?</h2>
				<div className='subtitle'>We offer you a chance to <span>earn unlimited money</span> from your youtube videos through promotion  of our apps from our Loftlime Inventory.</div>
				<AppBtnAlt style={{fontSize:'20px'}} onClick={()=>navigate("/youtuber?id=youtubeFORM")}>Know more</AppBtnAlt>
			</div>
			<div className='formprev'>
			<img src={utubeformPrev} alt="youtubeForm"/>	
			</div>
		</CardBgColor>
		</Container>
	);
};

const Container = styled.div`
	 padding-bottom: 154px;
	.join-cards {
		margin-top: 80px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: 20px 30px;
		position: relative;	
		.smallBall{
			position: absolute;
			left:26%;
			top:12%;
		}
		.bigBall{
			position: absolute;
			right:18%;
			bottom:-18%;
		}
		.btn{
			cursor: pointer;
			z-index: 1;
		}
		& > div {
			height: 100%;
			max-width: 440px;
			margin: auto;
			display: flex;
			padding: 60px 50px;
			flex-direction: column;
			align-items: flex-start;
			gap: 40px;
			line-height: 25px;
			.join-title {
				font-size: 18px;
			}
			.join-subtitle {
				font-weight: 200;
				opacity: 0.8;
			}
		}
	}
	.youtubeScreen{
		box-sizing: border-box;
		display: flex;
		align-items:flex-end;
		margin-top:80px;
		gap: 0 50px;
		overflow: hidden;
		.formprev{
		padding-top:20px;
		padding-left:84px;	
		margin-bottom:-3.6%;
		img{
			max-width:100%;
			height:auto;
			}
		}
		.content{
			flex-grow: 1;
			padding-top:60px;
			padding-bottom:50px;
			padding-right: 88px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			gap:18px 0;
			h2{
			color: #FFF;
			font-family: Lexend;
			font-size: 26px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px; 
			}
			.subtitle{
			color: #B9B9B9;
			font-family: Lexend;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px; 
			margin-bottom: 10px;
			span{
				color: #FFF;
			}
			}
		}
	}
	.youtubeScreen-mob{
		display: none;
	}
	.title{
		font-size: 18px;
		font-weight: 600;
	}
	@media screen and (max-width: 1364px) {
		.youtubeScreen{
			gap: 0 30px;
			.formprev{
				padding-left: 30px;
			}
			.content{
				padding-right: 30px;
				padding-left: 10px;

				h2{
					font-size: 2vw;
				}
				.subtitle{
					font-size: 1.4vw;
				}
			}
		}
	}

	@media screen and (max-width: 1025px) {
		.join-cards {
		& > div {
			height: 100%;
			max-width: 440px;
			padding: 60px 50px;
			line-height: 25px;
			.join-title {
				font-size: 16px;
				min-height: 60px;
			}
			.join-subtitle {
				font-size: 14px;
				min-height: 90px;	
			}
			.title{
				font-size: 12px;
			}
		}
	}
	}

@media screen and (max-width: 769px) {
	.join-cards {
		gap:40px 20px;
		.bigBall{
			position: absolute;
			right:18%;
			bottom:18%;
		}
		& > div {
			padding: 40px 30px;
			.join-title {
				font-size: 16px;
			}
			.join-subtitle {
				font-size: 14px;
			}
		}
		}
		.youtubeScreen{
	display: none;
}
.youtubeScreen-mob{
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	padding:54px 10% 0;
	.formprev{
	display: flex;
	justify-content: center;	
	img{
		max-width:70%;
		height:auto;
	}}
	.content{
		flex-grow: 1;
		padding-bottom:54px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		gap:18px 0;
		h2{
color: #FFF;
font-family: Lexend;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 32px; 
		}
		.subtitle{
			color: #B9B9B9;
			font-family: Lexend;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px; 
			margin-bottom: 10px;
			span{
				color: #FFF;
			}
			}
	}
}
}
`;

export default JoinUsSection;
