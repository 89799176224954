import React, { useState } from 'react';
import { styled } from 'styled-components';
import { ImArrowUpRight2 } from 'react-icons/im';


import { IoArrowUpCircle, IoMail } from 'react-icons/io5';
import {ReactComponent as Whatsapp} from '../assets/icons/whatsapp.svg';
import {ReactComponent as SendWhatsapp} from '../assets/icons/sendWhatsapp.svg';

import AppBtnAlt from '../components/AppBtnAlt';
import chats from '../assets/footer/linkedinChat.webp';
import chat from '../assets/footer/chat.webp';


const MessageSection = ({ style }) => {

	const [chatInput, setChatInput] = useState('');
	const [mailInput, setMailInput] = useState({
		subject: '',
		email: '',
	});

  const handleChatInputChange = (event) => {
    setChatInput(event.target.value);
  };
  const handleChangeMailInput = (event) => {
	const {name, value} = event.target;
	setMailInput({...mailInput, [name]: value});
  }

  const generateWhatsAppLink = () => {
    const phoneNumber = "+919919515999"; // Replace with actual WhatsApp number
    const encodedMessage = encodeURIComponent(chatInput);
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  };

	return (
		<Container className="app-padding" style={style}>
			<div className="chat-section">
      <img src={chat} alt="chat with us" />
      <div className="chat-box">
	  <Whatsapp/>
        <div className="chat-input">
          <input
            type="text"
            name=""
            placeholder="Message"
            value={chatInput}
            onChange={handleChatInputChange}
          />
          <a href={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer">
         { !chatInput?.length ||  <SendWhatsapp className='sendBtn' /> }
          </a>
        </div>
      </div>
    </div>

			<div className="message-right">
				<div className="email-us">
					<div className="m-title">New Message</div>
					<div className="message-info">
						<p>To: contact@loftlime.com</p>
						<input autoComplete='off' className='subject' type='text' name='subject' placeholder='Subject' value={mailInput?.subject} onChange={handleChangeMailInput}/>
						<textarea autoComplete='off' className='email' type='text' name='email' placeholder='Write your message' rows={4} value={mailInput?.email} onChange={handleChangeMailInput} />
					</div>
					<AppBtnAlt
						link={`mailto:mail@leadmint.io` + `?subject=${mailInput?.subject}` + `&body=${mailInput?.email}`}
						style={{ margin: 'auto', marginTop: 25, fontSize:'15px' }}
					>
						Email Us <ImArrowUpRight2 />
					</AppBtnAlt>
				</div>

				<div className="message-us">
					<img src={chats} alt="chat with us" />
					<AppBtnAlt
						link={'https://www.linkedin.com/company/leadminthq/'}
						style={{ margin: 'auto', marginTop: 25, fontSize:'15px' }}
					>
						Message us <ImArrowUpRight2 />
					</AppBtnAlt>
				</div>
			</div>
		</Container>
	);
};

const Container = styled.div`
	padding-bottom:154px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
	gap: 20px;

	.chat-section {
		width: 100%;
		padding: 20px;
		background: #17161a;
		border-radius: 10px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		img {
			width: 100%;
		}
		.chat-box {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 20px;
			svg {
				font-size: 25px;
			}
			.chat-input{
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 45px;
				flex: 1;
				border: 1.5px solid #b3b3b3ab;
				border-radius: 40px;
				overflow: hidden;
				background: linear-gradient(
						0deg,
						rgba(255, 255, 255, 0.3),
						rgba(255, 255, 255, 0.3)
					),
					linear-gradient(
						0deg,
						rgba(255, 255, 255, 0.1),
						rgba(255, 255, 255, 0.1)
					);
				input {
					padding: 2px 10px;
					border: none;
					width: 92%;
					height: 100%;
					font-size: inherit;
					color: #fff;
					background: transparent;
					&:focus {
						outline: none;
					}
				}
				svg {
					position: absolute;
					right: 6px;
					top: 50%;
					transform: translateY(-50%);
				}
				.sendBtn{
					display:block;
					height:80%;
				}
			}
		}
	}

	.message-right {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		gap: 20px;

		.email-us {
			padding: 0 0 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #17161a;
			border-radius: 10px;
			overflow: hidden;
			.m-title {
				background: #26252a;
				padding: 25px 30px 20px;
				width: 100%;
			}
			.message-info {
				flex: 1;
				width: 100%;
				p {
					border-bottom: 1px solid #636363a2;
					width: 90%;
					padding: 15px;
					margin-left: 10%;
					font-size: 16px;

				}
				.subject {
				border: none;
				padding: 15px;
				margin-left: 10%;
				margin-bottom: 4%;
				background: transparent;
				border-bottom: 1px solid #636363a2;
				width: 90%;
				color: #fff;
				font-size: 16px;
				&::placeholder {
					color: #fff;
					opacity: 0.5;
				}
				&:focus {
						outline: none;
					}
				}
				.email {
				border: none;
				padding: 15px;
				margin-left: 10%;
				margin-bottom: 4%;
				background: transparent;
				color: #fff;
				font-size: 16px;
				resize: none;
				scrollbar-width: none;
				&::placeholder {
					color: #fff;
					opacity: 0.5;
				}
				&:focus {
						outline: none;
					}
				}
			}
		}

		.message-us {
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #17161a;
			border-radius: 10px;

			img {
				height: auto;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 500px) {
		grid-template-columns: 1fr;
		.chat-section {
			.chat-box {
				gap:0;
				svg:nth-child(2){
					border: 1px solid #b3b3b3ab;
					height: 12vw;
				}
				.chat-input {
				border: 1px solid #b3b3b3ab;
					input{
						width: 74%;
						font-size: 14px;
					}

				}
			}
		}
	}
`;

export default MessageSection;
