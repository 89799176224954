
import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import colors from "../config/colors";
import SectionTitle from "../components/SectionTitle";
import logo from "../assets/icons/loftime.webp";
import publishBanner from "../assets/publishwithus.webp";
import gridImg from "../assets/commonImg/hero-grid.webp";
import greenGradBg from "../assets/commonImg/greenGradientBG.webp";
import ribbon from "../assets/commonImg/triangleSQuareRibbon.webp";
import greyRing from "../assets/commonImg/greyRing.webp";
import { ReactComponent as BackArrow } from "../assets/icons/backwhiteArrow.svg";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Popup from "../components/Popup";
import { emailPattern } from "../utils/constants";

const PublishWithUs = () => {
  const [loading, setLoading] = React.useState(false);
const [showPopup, setShowPopup] = React.useState(false);
const [formResponse, setFormResponse] = React.useState(null);
const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await (
        await fetch("https://api.loftlime.com/connectform", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
      ).json();
      if (response.error) {
       setFormResponse(response);
        setShowPopup(true);
      }
      if (response.message) {
        setFormResponse(response);
        setShowPopup(true);
      resetForm();

      }
      setLoading(false);
    } catch (error) {
      setFormResponse({ error: "Sorry something went wrong, Please try again!!!" });
      setShowPopup(true);
      setLoading(false);
    }
  };
  return (
    <Container>
    {showPopup && <Popup setShowPopup={setShowPopup} message={formResponse?.message || formResponse?.error} error={formResponse?.error && true} />}
  <div className="topWrapper">
    <img
      src={greenGradBg}
      alt="greenGradBg"
      style={{
        position: "absolute",
        top: "16%",
        right: "16%",
        width: "50%",
        height: "50%",
        zIndex: 0,
      }}
    />
    <img
      src={ribbon}
      alt="ribbon"
      style={{
        position: "absolute",
        top: "16%",
        width: "auto",
        height: "12vw",
        right: "-4.4%",
        zIndex: 0,
      }}
    />
    <img
      src={greyRing}
      alt="greyRing"
      style={{
        position: "absolute",
        width: "auto",
        height: "8vw",
        top: "16%",
        left: "-2vw",
        zIndex: 0,
      }}
    />
    <div className="top-section app-padding">
      <SectionTitle isSabo={true} center={true} className="section-title">
        <Link to={"/"} className="back-btn cursor">
          <BackArrow />
          <div>Back</div>
        </Link>
        <div className="title">
        Publish with <img src={logo} alt="logo" />
        </div>
      </SectionTitle>
      <p className="subtTitle">
      Join us and become a part of Loftlime. Elevate your products, reach a wider audience. Leverage Loftlime’s 10MN+ Userbase and grow with us!
      </p>
    </div>
    <div className="form-section app-padding">
      <div className="image-con">
        <img src={publishBanner} alt="logo" />
      </div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          appName: "",
          message: "",
        }}
        
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().matches(/^[A-Za-z]+$/, 'Name must contain only letters').required('Required').trim(),              
          email: Yup.string().email("Invalid email address")
          .matches(emailPattern, 'Invalid email address')
          .required("Required"),
          appName: Yup.string().required("Required").trim(),
          message: Yup.string().required("Required").trim().min(14,'Minimum 14 characters required')
        })}
        onSubmit={(values,actions) => {
          handleFormSubmit(values,actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} className="form-con">
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {errors.name && touched.name && (
              <div className="error">{errors.name}</div>
            )}

            <input
              type="email"
              name="email"
              placeholder="Your Email ID"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {errors.email && touched.email && (
              <div  className="error">{errors.email}</div>
            )}
            <input
              type="text"
              name="appName"
              placeholder="Your Application link"
              value={values.appName}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {errors.appName && touched.appName && (
              <div className="error">{errors.appName}</div>
            )}

            <textarea
              name="message"
              placeholder="Share more details.."
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              rows={4}
            />
            {errors.message && touched.message && (
              <div className="error">{errors.message}</div>
            )}
            {loading ? (
              <button type="button" className="btn-send sending">
                Sending...
              </button>
            ) : (
              <button type="submit" className="btn-send">
                Send
              </button>
            )}
          </form>
        )}
      </Formik>
    </div>
  </div>
  <Footer />
</Container>
  )
}

export default PublishWithUs

const Container = styled.div`
  min-height: 100vh;
  color: ${colors.white};
  overflow: hidden;
  background-color: ${colors.black};
  .topWrapper {
    background: url(${gridImg});
    background-size: 200px;
    background-repeat: repeat;
    position: relative;
  }
  .top-section {
    padding-top: 78px;
    padding-bottom: 64px;
    .back-btn {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #fff;
      font-family: Lexend;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: auto;
    }
    .section-title {
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      .title{
        font-family: Sabo;
      }
      div:nth-child(2) {
        margin-right: auto;
      }
    }
    .subtTitle {
      color: #fff;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.7;
    }
  }
  .form-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 154px;
    .image-con {
      width: 40%;
      height: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .form-con {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 50%;
      input,
      textarea {
        background: transparent;
        padding: 18px;
        margin-bottom: 20px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(20px);
        color: #fff;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        &::placeholder {
          color: #fff;
          opacity: 0.6;
        }
      }
      textarea {
        padding: 18px auto 70px 24px;
        resize: none;
      }
      .btn-send {
        color: #fff;
        width:8vw;
        font-family: Lexend;
        margin-top: 20px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        border-width: 2px;
        background-color: transparent;
        border-style: solid;
        border-image: linear-gradient(
            to right,
            ${colors.yellow},
            ${colors.green}
          )
          1;
        padding: 8px 20px;
        svg {
          transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        &:hover {
          cursor: pointer;
          border-image: linear-gradient(
              to left,
              ${colors.yellow},
              ${colors.green}
            )
            1;
          svg {
            transform: rotate(45deg);
          }
          &.sending {
            background-color: rgba(255, 255, 255, 0.2);
            cursor: not-allowed;
          }
        }
      }

      .error {
        color: red;
        font-size: 14px;
        font-family: Lexend;
        font-weight: 300;
        margin-bottom: 10px;
        margin-top: -10px;
        animation: shake 0.5s ease-in-out;
      }
    }
  }
  @keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 90% {
    transform: translateX(-10px);
  }
  20%, 80% {
    transform: translateX(10px);
  }
  30%, 70% {
    transform: translateX(-10px);
  }
  40%, 60% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
}
  @media screen and (max-width: 650px) {
    .top-section {
      .subtTitle {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .form-section {
      flex-direction: column;
      .image-con {
        width: 100%;
      }
      .form-con {
        width: 100%;
      .btn-send{
        width:33%;
      }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .form-section {
      .form-con {
      .btn-send{
        width:34%;
      }
      }
    }
  }
`;
