import React from 'react';
import { styled } from 'styled-components';
import colors from '../config/colors';

const StatCard = ({ Icon, title, subtitle }) => {
	return (
		<Container>
			<Icon />
			<div>
				<p className="stat-title">{title}</p>
				<p className="stat-subtitle">{subtitle}</p>
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
	svg {
		font-size: 26px;
		margin-top: 5px;
	}
	div {
		width: fit-content;
		display: flex;
		flex-direction: column;
		gap: 8px;
		.stat-title {
			font-size: 30px;
			font-family: Sabo;
			color: ${colors.green};
		}
		.stat-subtitle {
			font-weight: 300;
			letter-spacing: 0.5px;
			line-height: 23px;
		}
	}
`;

export default StatCard;
