import React from 'react';
import { styled } from 'styled-components';

import colors from '../config/colors';

const SectionTitle = ({ className, center, isSabo, children }) => {
	return isSabo ? (
		<SaboTitle
			className={className}
			style={{ textAlign: center ? 'center' : 'initial' }}
		>
			{children}
		</SaboTitle>
	) : (
		<Container
			className={className}
			style={{ textAlign: center ? 'center' : 'initial' }}
		>
			{children}
		</Container>
	);
};
const Container = styled.p`
	font-size: 47px;
	font-weight: 300;
	span {
		font-weight: 600;
		background: linear-gradient(
			to right,
			${colors.yellow},
			${colors.greenYellow}
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	img {
		height: 40px;
		width: auto;
	}

	@media screen and (max-width: 1000px) {
		font-size: 35px;
		img {
			height: 24px;
		}
	}
	@media screen and (max-width: 700px) {
		font-size: 30px;

		img {
			height: 24px;
		}
	}
	@media screen and (max-width: 500px) {
		font-size: 28;
	}
`;

const SaboTitle = styled.p`
	text-align: center;
	font-size: 47px;
	font-family: Sabo;
	img {
		height: 40px;
	}
	span {
		font-family: inherit;
		font-weight: 600;
		background: linear-gradient(
			to right,
			${colors.yellow},
			${colors.greenYellow}
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (max-width: 1000px) {
		padding: 0 0 20px;
		font-size: 24px;
		img {
			height: 24px;
		}
	}
`;

export default SectionTitle;
