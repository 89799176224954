import React from "react";
import { styled } from "styled-components";
// import defaultIcon from '../assets/app-icon.webp';
import AppBtnAlt from "./AppBtnAlt";
import colors from "../config/colors";
import { Link } from "react-router-dom";

const AppCard = ({
  isActive = false,
  appName = "",
  appDesc = "",
  appImg,
  iconImg,
  link,
  to,
  onHover,
  onleave,
  btn = true,
}) => {
  return (
    <Link to={"/products?app=" + to}>
      <Container
        isActive={isActive}
        onMouseEnter={onHover}
        onMouseLeave={onleave}
      >
        <div className="img-con">
          <img src={appImg} alt="banner img" className="banner-img" />
 		<div className="blackgrad"></div>
          {iconImg && (
            <div className="app-icon">
              <img src={iconImg} alt="icon img" />
            </div>
          )}
        </div>
        <div className="appInfo">
          <h3>{appName}</h3>
          {appDesc && btn ? (
            <AppBtnAlt>{appDesc}</AppBtnAlt>
          ) : (
            <div className="desc">{appDesc}</div>
          )}
        </div>
      </Container>
    </Link>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
 
&:hover .app-icon  img {
		transform: scale(1.1);
	}

  .app-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -3vw;
    z-index: 10;
    img {
      height: 100%;
      width: 100%;
      border-radius: 24px;
	  transition: transform .3s ease-in-out;
    }
  }
  .img-con {
    display: flex;
    justify-content: center;
    left: 0;
    position: relative;
    z-index: 1;
    border-radius: 18px;
    img {
      height: 100%;
      width: 100%;
    }
    .banner-img {
      border-radius: 24px;
    }
    .blackgrad {
	  transition: opacity .3s ease-in-out;
	  opacity: 1;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30%;
      z-index: 2;
      border-radius: 0 0 24px 24px;
      background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.02) 100%);
    }
	&:hover .blackgrad {
		opacity: 0;
	
	}
  }
  .appInfo {
    margin-top: 3.4vw;
    h3 {
      color: ${({ isActive }) => (isActive ? "#FFF" : "#BABABA")};
      text-align: center;
      font-family: Lexend;
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
      line-height: 52px;
      letter-spacing: 0.34px;
    }

    .desc {
      text-align: center;
      font-family: Lexend;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.2px;
      padding: 2px 20px;
      background: -webkit-linear-gradient(
        left,
        ${colors.yellow},
        ${colors.green}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export default AppCard;
