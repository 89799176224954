import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import colors from '../config/colors';

const AppBtnAlt = ({ link, children, style, onClick, className }) => {
	return (
		<Link to={link} className={className}>
			<Container style={style} onClick={onClick}>{children}</Container>
		</Link>
	);
};

const Container = styled.div`
	border-width: 2px;
	border-style: solid;
	border-image: linear-gradient(to right, ${colors.yellow}, ${colors.green}) 1 ;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	width: fit-content;
	svg {
		transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}
	&:hover {
		border-image: linear-gradient(
				to left,
				${colors.yellow},
				${colors.green}
			)
			1;
		svg {
			transform: rotate(45deg);
		}
	}
`;

export default AppBtnAlt;
