import React from "react";
import styled from "styled-components";
import AppBtnAlt from "./AppBtnAlt";
import Lottie from 'react-lottie';
import greenTick from '../assets/lottie/greenTick.json';
import warning from '../assets/lottie/warning.json';

const Popup = ({ message, error = false, setShowPopup, connectForm = true }) => {
    const defaultOptions = {
        isFinite: true,
        autoplay: true,
        initialSegment: error ? null : [0, 50],
        loop: false,
        animationData: error ? warning : greenTick,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
  return (
    <Container>
      <div className="popUp">
     <div className={`lottie ${error && 'padding'}`}>
     <Lottie
       options={defaultOptions} height={error ? 140 : 200} width={error ? 140 : 200}/>
       </div>
       {!connectForm ? <h3>{error ? "Something went wrong!" : "Thank you for subscribing!"}</h3> :
       <h3>{error ? "Something went wrong!" : "Thank you for contacting us!"}</h3>
       }
        <p>{message}</p>
        <AppBtnAlt onClick={()=>setShowPopup(false)} style={{marginBottom:'40px'}}>
          <span className="btn">OK</span>
        </AppBtnAlt>
      </div>
    </Container>
  );
};

export default Popup;

const Container = styled.div`
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0; 
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  backdrop-filter: blur(5px);

  .popUp {
    width: 42%;
    height: 405px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #110e16;
    border-radius: 40px;
    border: 1px solid rgba(102,102,102,0.4);
    .lottie{
        &.padding{
            box-sizing: border-box;
            padding-top: 30px;
            padding-bottom: 20px;
        }
    }
    h3 {
      color: #fff;
      text-align: center;
      font-family: Lexend;
      line-height: 32px;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }
    p {
      width: 80%;
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0.2px;
      opacity: 0.7;
      margin:6px auto 30px auto;
    }
    .btn {
      width: 10vw;
      text-align: center;
    }
  }

@media screen and (max-width: 1025px) {
  .popUp{
    width: 60%;
    h3{
      font-size: 22px;
      line-height: 28px;
    }
    p{
      font-size: 18px;
      line-height: 26px;
      margin: 10px auto 28px auto;
    }
  }
}
@media screen and (max-width: 769px) {
  .popUp{
    width: 60%;
    .lottie{
      path{
      width: 60%;
      }
    }
    h3{
      font-size: 3.446vw;
      line-height: 28px;
    }
    p{
      font-size: 2.438vw;
      line-height: 26px;
      margin: 10px auto 28px auto;
    }
  }
}
@media screen and (max-width: 426px) {
  .popUp{
    width: 90%;
    .lottie{
      path{
      width: 60%;
      }
    }
    h3{
      font-size: 4.446vw;
      line-height: 28px;
    }
    p{
      font-size: 3.438vw;
      line-height: 26px;
      margin: 10px auto 28px auto;
    }
  }
}
`;
