import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProductPage from './pages/ProductPage';
import RewardPage from './pages/RewardPage';
import YoutubePage from './pages/YoutubePage';
import PartnerWithUs from './pages/PartnerWithUs';
import PublishWithUs from './pages/PublishWithUs';
import ErrorPage from './pages/ErrorPage';
import CareerPage from './pages/CareerPage'
import TermPage from './pages/TermsPage'
import PrivacyPage from './pages/PrivacyPolicy'


const router = createBrowserRouter([
	{
		path: '/',
		element: <HomePage />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/about',
		element: <AboutPage />,
	},
	{
		path: '/products',
		element: <ProductPage />,
	},
	{
		path: '/rewards',
		element: <RewardPage />,
	},
	{
		path: '/youtuber',
		element: <YoutubePage />,
	},
	{
		path: '/partnerwithus',
		element: <PartnerWithUs />,
	},
	{
		path: '/publishwithus',
		element: <PublishWithUs />,
	},
	{
		path: '/career',
		element: <CareerPage />,
	},
	{
		path: '/termsandconditions',
		element: <TermPage />,
	},
	{
		path: '/privacypolicy',
		element: <PrivacyPage />,
	},
	{
		path: '*',
		element: <ErrorPage />,
	},
])

const App = () => {
	return <RouterProvider router={router} />;
};

export default App;
