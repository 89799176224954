import React from 'react'
import { styled } from 'styled-components'
import CardBgColor from './CardBgColor'

const UserStory = ({ img, name, story }) => {
    return (
        <CardBgColor>
            <Container>
                <div className="image" style={{
                    backgroundImage: `url(${img})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'

                }}>
                </div>

                <p className="meet">{name}</p>

                <p className="story">{story}</p>
            </Container>
        </CardBgColor>
    )
}

const Container = styled.div`
width: 100%;
height:100%;
padding:40px 50px;
    display: flex;
    flex-direction: column;
    gap:10px;
    .image{
        width: 60px;
        height: 60px;
        border-radius: 50px;
    }
    .story{
            opacity:.7;
            font-weight: 200;
            line-height: 25px;
            letter-spacing: .4px;
        
    }
`

export default UserStory