import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import colors from "../config/colors";
import SectionTitle from "../components/SectionTitle";
import CardBgColor from "../components/CardBgColor";
import * as Yup from "yup";
import { Formik } from "formik";
import { emailPattern } from "../utils/constants";
import Slider from "react-slick";
import AppBtnAlt from "../components/AppBtnAlt";
import { useNavigate, useSearchParams } from "react-router-dom";
import Popup from "../components/Popup";
// img
import gridImg from "../assets/commonImg/hero-grid.webp";
import greenGradBg from "../assets/commonImg/greenGradientBG.webp";
import ribbon from "../assets/commonImg/triangleSQuareRibbon.webp";
import greyRing from "../assets/commonImg/greyRing.webp";
import { ReactComponent as YoutubeIcon } from "../assets/icons/youtubeIcon.svg";
import formBanner from "../assets/youtube/utubeFormBanner.webp";
import utubeformPrev from "../assets/youtube/youtubeformSM.webp";
import monitor from "../assets/youtube/monitorSM.webp";
import earnMoney from "../assets/youtube/earnGamesSM.webp";
import {ReactComponent as Cross} from "../assets/icons/crossYoutube.svg" 



const YoutubePage = () => {
const [videoURL, setVideoUrl] = useState(null)
const [videoData, setVideoData] = useState(null)
const [showPopup, setShowPopup] = React.useState(false);
const [formResponse, setFormResponse] = React.useState(null);
const [searchParam] = useSearchParams();
const youtId = searchParam.get("id");

const navigate = useNavigate();

const navigatetoForm = ()=>{
  const form = document.getElementById("youtubeFORM"); 
      const offset = 0; 
      const targetScrollPosition = form.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });
}
const handleClick = (data,event) => {
  setVideoUrl(data);
  event.stopPropagation()
};

const handleYoutubeForm = async(values,{resetForm}) => {
try{const response = await fetch("https://api.loftlime.com/youtuberformfill",{
  method:"POST",
  headers:{
    "Content-Type":"application/json"
  },
  body:JSON.stringify(values)
})
const data = await response.json();
if (!response.ok) {
  setFormResponse(data);
  setShowPopup(true);
resetForm();
}
else{
  setFormResponse(data);
  setShowPopup(true);
}
}catch(error){
  console.log(error);
  setFormResponse({ error: "Sorry something went wrong, Please try again!!!" });
  setShowPopup(true);
}
}

const fetchYoutuberData = async () => {
  try {
    const res = await fetch("https://api.loftlime.com/getyoutuberData");
    const data = await res.json();
    setVideoData(data);
  } catch (error) {
    console.log(error);
  }

}
  useEffect(() => {
  const form = document.getElementById(youtId); 
    const scrollToApp = () => { 
      const offset = 0; // Amount of pixels to reduce by
      const targetScrollPosition = form.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });}
      if (form) scrollToApp();
      else window.scrollTo(0, 0); 
    fetchYoutuberData();
    const clearVideoContainer = ()=>{
      setVideoUrl(null)
    }
    window.addEventListener('click',clearVideoContainer)
    return () => window.removeEventListener('click',clearVideoContainer)
  // eslint-disable-next-line 
  }, []);
  return (
    <Container>
      <NavBar />
      {showPopup && <Popup setShowPopup={setShowPopup} message={formResponse?.message || formResponse?.error} error={formResponse?.error && true} />}

      <div className="topWrapper">
        <img
          src={greenGradBg}
          alt="greenGradBg"
          style={{
            position: "absolute",
            top: "16%",
            right: "16%",
            width: "50%",
            height: "50%",
            zIndex: 0,
          }}
        />
        <img
          src={ribbon}
          alt="ribbon"
          style={{
            position: "absolute",
            top: "46%",
            width: "auto",
            height: "12vw",
            right: "-4.4%",
            zIndex: 0,
          }}
        />
        <img
          src={greyRing}
          alt="greyRing"
          style={{
            position: "absolute",
            width: "auto",
            height: "8vw",
            top: "36%",
            left: "-2vw",
            zIndex: 0,
          }}
        />

        <div className="top-section app-padding">
          <SectionTitle className="title" isSabo center>
            <YoutubeIcon className="utubeIcon" style={{ marginRight: "-2%", maxWidth:"100%", height:'auto' }} />
            Youtuber’s <span style={{ fontFamily: "inherit" }}>Section</span>
          </SectionTitle>
          <SectionTitle center className='title'>Hi, Youtube Creators!</SectionTitle>
          <div className="content">
            <span className="con-beg">We offer you a chance to </span>
            <span className="highlight">earn unlimited money </span>
            <span className="con-end"> 
               from your youtube videos through promotion of our apps from our
              Loftlime Inventory.
            </span>
          </div>
        </div>
      </div>

      <div className="youtuber-list app-padding" >
        <SectionTitle center>
          Youtuber’s earning from <span>Our Platform</span>
        </SectionTitle>
        <p className="subtitle">
          Have a peek at how much our youtube partners have earned from our
          platform
        </p>
        <div className="">
       {videoURL ?
       <div className="bigScreenPlayer" style={{position:'relative'}}>
            <div className="cross" onClick={()=>{
              setVideoUrl(null)
            }}><Cross style={{zIndex:200, fontSize:"200px"}}/></div>
            <iframe title="Video Player 1" src={`${videoURL}`} frameborder="0"  ></iframe>
            </div>
:  
<Slider
				autoplay
				dots={false}
				infinite
				speed={500}
				autoplaySpeed={2000}
				slidesToShow={3}
				slidesToScroll={1}
				className="swiper-section app-padding"
        arrows={false}
			>
      {
        videoData && videoData.map((video,index)=>(
          <CardBgColor className="youtubeCard" key={video?.Name}  >
            <div style={{position:'relative'}} className="videoContainer" >
            <div className="cover" onClick={(e) => handleClick(video?.VideoLink, e)}></div>
            <iframe title="Video Player 1" className="thumb" src={video?.VideoLink} frameborder="0"  allowFullScreen></iframe>
            </div>
            <div className="ut-details">
              <img src={video?.ChannelLogo} alt="thumbnail" />
              <div>
                <h4>{video?.Name}</h4>
                <p>{video?.Subscribers}</p>
              </div>
            </div>
            <div className="ut-foot">
              <span>Earnings</span>
              <h5>₹{video?.Amount}+</h5>
            </div>
          </CardBgColor>
        ))
      } 
			</Slider>
}
       
      </div>
     {!videoURL && 
     <Slider
				autoplay
				dots={false}
				infinite
				speed={500}
				autoplaySpeed={2000}
				slidesToShow={1}
				slidesToScroll={1}
				className="swiper-section-mobile app-pading"
        arrows={false}
			>
      {
        videoData && videoData.map((video,index)=>(
			 <CardBgColor className="youtubeCard" key={video?.Name}>
       <div style={{position:'relative'}} className="videoContainer" >
            <div className="cover" onClick={(e) => handleClick(video?.VideoLink, e)}></div>
            <iframe title="Video Player 1" className="thumb" src={video?.VideoLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="ut-details">
              <img src={video?.ChannelLogo} alt="thumbnail" />
              <div>
                <h4>{video?.Name}</h4>
                <p>{video?.subscribers}</p>
              </div>
            </div>
            <div className="ut-foot">
              <span>Earnings</span>
              <h5>₹{video?.Amount}+</h5>
            </div>
          </CardBgColor>
        ))
      }
			</Slider>}
        <p className="manymore">and many more...</p>
      </div>


      <div className="earn-infosection app-padding">
        <SectionTitle center>
          How to <span>Earn?</span>
        </SectionTitle>
        <div className="sub-title ">
        Earn from Loftlime by doing what you love, create a video of any of our app from our Loftlime inventory and get cash rewards on every conversion you bring in! Fill out the Youtube creator’s form and get in touch with us. 
        </div>
        <div className="cards-container ">
          <CardBgColor className="youtubeScreen">
            <div className="formprev">
              <img src={utubeformPrev} alt="youtubeForm" />
            </div>
            <div className="content" style={{zIndex:100}}>
              <h2>Share your Youtube Channel</h2>
              <div className="subtitle">
              Fill out Youtube Creator’s form, share your name, youtube channel link and other details with us for review.
              </div>
              <AppBtnAlt style={{ fontSize: "100.042%" }} onClick={navigatetoForm}>Youtube Creator Form</AppBtnAlt>
            </div>
          </CardBgColor>
          <CardBgColor className="youtubeScreen">
          <div className="formprev">
              <img src={monitor} alt="youtubeForm" />
            </div>
            <div className="content" style={{zIndex:100}}>
              <h2>Create a video for an app of your choice</h2>
              <div className="subtitle">
              We provide you with a personlized link and help you out to create a video of an application of your choice from our Loftlime’s Inventory
              </div>
              <AppBtnAlt className='btn-right' style={{ fontSize: "100.042%" }} onClick={()=>navigate('/products')}>Explore Our App’s</AppBtnAlt>
            </div>
          </CardBgColor>
          <CardBgColor className="youtubeScreen">
            <div className="formprev">
              <img src={earnMoney} alt="youtubeForm" />
            </div>
            <div className="content" style={{zIndex:100}}>
              <h2>Earn money on every conversion!</h2>
              <div className="subtitle">
              Share your personalized link with your userbase & Earn unlimited rewards on every conversion!
              </div>
              <AppBtnAlt style={{ fontSize: "100.042%" }} onClick={navigatetoForm}>Youtube Creator Form</AppBtnAlt>
            </div>
          </CardBgColor>
        </div>
      </div>

      <div id="youtubeFORM" className="youtuberForm-section app-padding">
        <Formik
          initialValues={{
            name: "",
            email: "",
            subscribers: "",
            channelLink: "",
            message: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string()
            .matches(/^[A-Za-z\s]+$/, "Name must contain only letters and spaces").required("Required")
              .trim(),
            email: Yup.string()
              .email("Invalid email address")
              .matches(emailPattern, "Invalid email address")
              .required("Required"),
            subscribers: Yup.string()
    // .matches(/^\d+$/, 'Subscribers must contain numbers only')
    .required('Required').trim(),
            channelLink: Yup.string().required("Required").url(),
          })}
          onSubmit={(values,actions) => {
            handleYoutubeForm(values,actions);
          }}
        >
          {({ handleChange, handleBlur, values, errors, touched, handleSubmit }) => (
            <div className="form-container">
              <div>
                <SectionTitle center isSabo className="title">
                  <YoutubeIcon className="utubeIcon" style={{ marginRight: "4px" }} />
                  Youtube
                </SectionTitle>
                <SectionTitle center isSabo>
                  Creator’s Form
                </SectionTitle>
              </div>
              <form className="form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors.name && touched.name && (
                  <div className="error">{errors.name}</div>
                )}
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors.email && touched.email && (
                  <div className="error">{errors.email}</div>
                )}
                <input
                  type="text"
                  name="channelLink"
                  placeholder="Your Channel Link"
                  value={values.channelLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors.channelLink && touched.channelLink && (
                  <div className="error">{errors.channelLink}</div>
                )}
                <input
                  type="text"
                  name="subscribers"
                  placeholder="No. of Subscribers"
                  value={values.subscribers}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors.subscribers && touched.subscribers && (
                  <div className="error">{errors.subscribers}</div>
                )}
                <textarea
                  name="message"
                  placeholder="Share more details.."
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors.message && touched.message && (
                  <div className="error">{errors.message}</div>
                )}
                <button type="submit" className="btn-send">
                  Send
                </button>
              </form>
            </div>
          )}
        </Formik>
      </div>
      <Footer />
    </Container>
  );
};

export default YoutubePage;

const Container = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  min-height: 100vh;
  overflow: hidden;

  .topWrapper {
    background: url(${gridImg});
    background-size: 200px;
    background-repeat: repeat;
    position: relative;
    padding-top: 204px;
    padding-bottom: 154px;
    .top-section {
      min-height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 17px;
        margin-bottom: 40px;
      }
      .content {
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-family: Lexend;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px; /* 150% */

        .con-beg,
        .con-end {
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-family: Lexend;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 60px;
        }
        .con-end {
          font-weight: 300;
        }
        .highlight {
          background: linear-gradient(
            to right,
            ${colors.yellow},
            ${colors.greenYellow}
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Lexend;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
        }
      }
    }
  }

  .bigScreenPlayer{
        margin-top: 60px;
        width:100%;
        height:390px;
        position:relative;
        .cross{
          position:absolute;
          display:flex;
          justify-content:center;
          align-items:center;
          z-index:200;
          right:2%;
          top:2%;
          background:white;
          padding:6px;
          border-radius:100%;
          cursor:pointer;
        }
        iframe{
          width:100%;
          height:100%;
          border-radius:10px;
        position:absolute;
        z-index:1;

        }
      }

  .youtuber-list {
    max-width: 100%;
    .subtitle {
      margin-top: 20px;
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.7;
    }
    
    .swiper-section,.swiper-section-mobile{
      margin:60px auto 0;
    }
    .swiper-section-mobile{
      display:none;
    }
    .youtubeCard {
      padding: 22px;
      max-width: 390px;
      .playIcon{
        position: absolute;
        top: 40%;
        left: 40%;
      }

      .cover{
        position:absolute;
        cursor:pointer;
        width:100%;
        height:100%;
      }
      
      .videoContainer{
        padding:auto;
      }
      .thumb{
        width: 346px;
        height: 202px;
        border-radius: 20px;
      }
      .ut-details {
        padding: 20px 0;
        display: flex;
        align-items: center;
        gap: 0 20px;
        border-bottom: 1px solid #666666;
        img {
          width: 70px;
          height: 70px;
          border-radius: 100%;
        }
        div {
          display: flex;
          flex-direction: column;
          h4,
          p {
            color: #fff;
            font-family: Lexend;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            text-align: left;
          }
          p {
            font-size: 20px;
            font-weight: 300;
            opacity: 0.7;
          }
        }
      }
      .ut-foot {
        display: flex;
        justify-content: space-between;
        padding: 24px 22px 32px;

        span {
          color: #666;
          font-family: Lexend;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 42px;
        }
        h5 {
          color: #fff;
          text-align: right;
          font-family: Lexend;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 42px;
        }
      }
    }
    .manymore {
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.7;
      margin-top: 60px;
    }
  }

  .earn-infosection {
    margin-top: 154px;
    .sub-title {
      margin-top: 30px;
      color: #fff;
      text-align: center;
      font-family: Lexend;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.2px;
      opacity: 0.7;
    }
    .youtubeScreen {
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      gap: 40px;
      margin-top: 80px;
      overflow: hidden;
      padding:60px 60px 0;
      
      .formprev {
        width: 40%;
        overflow: hidden;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .content {
        width: 70%;
        overflow: hidden;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 18px 0;
        h2 {
          color: #fff;
          font-family: Lexend;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }
        .subtitle {
          color: #b9b9b9;
          font-family: Lexend;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          margin-bottom: 10px;
          span {
            color: #fff;
          }
        }
      }

      &:nth-child(2) {
        gap:100px;
        flex-direction: row-reverse;
        .content{
          text-align:right;
          .btn-right{
            margin-left: auto;
          }
        }
      }
    }
  }

  .youtuberForm-section {
    padding: 154px 0 0;
    margin: 154px auto 154px;
    background: url(${formBanner});
    background-size: 90% 100%;
    background-position: center;
    background-repeat: no-repeat;

    .form-container {
      background: #100d15;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px;
      margin-top: 80px;
      width: fit-content;
      margin: 0 auto;
      border-radius: 24px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-top: 80px;
        input,
        textarea {
          border-radius: 10px;
          background: #100d15;
          padding: 18px 18px 18px 24px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(20px);
          color: #fff;
          font-family: Lexend;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          width: 100%;
          &::placeholder {
            opacity: 0.6;
          }
        }
        textarea {
          resize: none;
        }
        .btn-send {
          color: #fff;
          width: fit-content;
          font-family: Lexend;
          margin-top: 20px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.2px;
          border-width: 2px;
          background-color: transparent;
          border-style: solid;
          border-image: linear-gradient(
              to right,
              ${colors.yellow},
              ${colors.green}
            )
            1;
          padding: 8px 20px;
          svg {
            transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          }
          &:hover {
            cursor: pointer;
            border-image: linear-gradient(
                to left,
                ${colors.yellow},
                ${colors.green}
              )
              1;
            svg {
              transform: rotate(45deg);
            }
            &.sending {
              background-color: rgba(255, 255, 255, 0.2);
              cursor: not-allowed;
            }
          }
        }
      }
      .error {
        color: red;
        font-size: 14px;
        font-family: Lexend;
        font-weight: 300;
        margin-bottom: 10px;
        margin-top: -10px;
        animation: shake 0.5s ease-in-out;
      }
    }
  }
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    90% {
      transform: translateX(-10px);
    }
    20%,
    80% {
      transform: translateX(10px);
    }
    30%,
    70% {
      transform: translateX(-10px);
    }
    40%,
    60% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(0);
    }
  }

@media screen and (max-width: 1441px) {
  .youtuber-list{
      .swiper-section{
        padding: 0 5vw;
        .youtubeCard{
          max-width: 24.625vw;
          ${'' /* max-width: 100%; */}
          .thumb{
        width: 100%;
        height: auto;
          }
          .ut-details{
            img{
              width: 3.704vw;
              height: 3.704vw;
            }
            div{
              h4{
                font-size: 1.82vw;
              }
              p{
                font-size: 1.233vw;
              }
            }
          }
          .ut-foot{
                span{
                  font-size: 1.42vw;
                }
                h5{
                  font-size: 1.738vw;
                }
              }
        }
        }
    }
}
@media screen and (max-width: 1025px) {
  .youtuber-list{
      .swiper-section{
        padding: 0 1vw;
        .youtubeCard{
          max-width: 20.625vw;
          .ut-details{
            img{
              width: 3.604vw;
              height: 3.604vw;
            }
            div{
              h4{
                font-size: 1.4vw;
              }
              p{
                font-size: 1vw;
              }
            }
          }
          .ut-foot{
            padding: 1.042vw 1.042vw 1.563vw;
                span{
                  font-size: 1.042vw;
                }
                h5{
                  font-size: 0.938vw;
                }
              }
        }
        }
    }
}

  @media screen and (max-width: 1293px) {
  .topWrapper{
    .top-section{
      .utubeIcon{
        width: 60px;
      }
      .title{
        font-size: 40px;
      }
      .content{
        font-size: 34px;
        line-height: 40px;
        .con-beg,.con-end{
          font-size: 34px;
        }
      }
    }
    }

    .earn-infosection{
     .cards-container{ 
      .youtubeScreen{
        padding:2vw 3vw 0;
        .formprev{
          width: 30%;
        }
        .content{
        padding-top:1vw;
        padding-bottom:1.563vw;
        h2{
        font-size: 1.6vw;
        line-height: 1.667vw;
        }
        .subtitle{
          font-size: 1.2vw;
        line-height: 1.667vw;
        }
      }}
    }
  }
  }
  
@media screen and (max-width: 768px) {
.youtuber-list{ 
  .swiper-section{
      display:none;
    }
    .swiper-section-mobile{
      display:block;
      text-align:center;
    }
    }
  .youtuberForm-section{
    background:none;
  }
  .earn-infosection{
    .cards-container{
    .youtubeScreen{
      display:flex;
      flex-direction:column;
      padding:4vw 5vw 0;
      .content{
        padding-top:2.3vw;
        order:1;
        width: 100%;
        gap: 4.6vw;
        h2{
          font-size:30px;
          line-height: 8vw;
        }
        .subtitle{
          font-size: 3vw;
          line-height: 4vw;
        }
      }
      .formprev{
        order:2;
        width: 100%;
        height: 100%;
        text-align:center;
        margin:0 auto;
      }
    }
  }
  }
  }
  
  @media screen and (max-width: 425px) {
    .topWrapper{
      .top-section{
        .title{
          font-size: 6vw;
        }
      }
    }
    .swiper-section-mobile{
      .youtubeCard{
        .thumb{
          max-width: 100%;
        }
        max-width: 90%;
        .ut-details{
          img{
            width: 14vw;
            height: 14vw;
          }
          div{
            h4{
              margin-top: 2vw;
              font-size: 6vw;
            }
            p{
              font-size: 4vw;
            }
          }
        }
        .ut-foot{
              padding: 2vw 2vw 3vw;
              span{
                font-size: 6vw;
              }
              h5{
                font-size: 8vw;
              }
            }
      }
    }

    .youtuberForm-section{
      .form-container{
        padding:8vw 3vw;
        width: 100%;
        .title{
          font-size: 8vw;
          .utubeIcon{
            width: 12vw;
            margin-right: 4vw;
          }
        }
        .form{
        input,textarea{
          padding: 4vw 2vw;
          font-size: 3.6vw;
          width: 100%;
        }
        .btn-send{
          padding: 2vw 8vw;
          font-size: 4vw;
        }
        }
      }
    }
  }
`;
