import React from 'react';
import { styled } from 'styled-components';

const CardBgColor = ({ children, style, className }) => {
	return (
		<Container style={style} className={className}>
			{children}
		</Container>
	);
};

const Container = styled.div`
border-radius: 26px;
background: linear-gradient(to bottom, #1f1f1f , #17171740 25%);
${'' /* box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20), 5px 5px 40px 0px rgba(13, 9, 65, 0.50); */}
backdrop-filter: blur(20px);
&:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 26px;
        padding: 1.5px;
        background: linear-gradient(to top, #171717 15%, #3f3f3f);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
`;

export default CardBgColor;
